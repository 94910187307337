import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import AdminNavbar from "../../AdminNavbar/AdminNavbar";
import {
  Box,
  Button,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { host, v } from "../../../../config";
import LoadingSpinner from "../../../CommonWidget/Loading/LoadingSpinner";
import Sidebar from "../Sidebar/Sidebar";

const CourseMaster = () => {
  const navigate = useNavigate();

  const [loading, Loading] = useState(false);
  const [modalOpen, ModalOpen] = useState(false);

  const [institutions, Tutors] = useState([]);
  const [newCourse, NewCourse] = useState("");

  async function Logout() {
    localStorage.clear();
    navigate("/login");
  }

  useEffect(() => {
    fetchTutors();
  }, []);

  function fetchTutors() {
    fetch(`${host}/api/${v}/admin/listcourses`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        Tutors(data);
      })
      .catch((error) => {
        // handleClick({ vertical: "top", horizontal: "right" });
        console.error("Error during fetch:", error);
      })
      .finally(() => {
        // navigate("/register/steps/welcome");
        Loading(false);
      });
  }

  function handleClose() {}

  function addCourse() {
    fetch(`${host}/api/${v}/admin/addcourse`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
      body: JSON.stringify({
        newcourse: newCourse,
      }),
    });
    // .then((response) => {
    //   if (!response.ok) {
    //     throw new Error(`HTTP error! Status: ${response.status}`);
    //   }
    //   return response.json();
    // })
    // .then((data) => {
    //   Tutors(data);
    // })
    // .catch((error) => {
    //   // handleClick({ vertical: "top", horizontal: "right" });
    //   console.error("Error during fetch:", error);
    // })
    // .finally(() => {
    //   // navigate("/register/steps/welcome");
    //   Loading(false);
    // });
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 3,
  };

  return (
    <div className={styles.bg}>
      <AdminNavbar />
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" component="h3">
            Add a Course
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <label>Course Name</label>
            <TextField
              margin="normal"
              required
              fullWidth
              id="newCourse"
              label="Course Name"
              name="newCourse"
              autoComplete="newCourse"
              value={newCourse}
              onChange={(v) => NewCourse(v.target.value)}
              autoFocus
            />
            <Button
              variant="contained"
              aria-label="outlined primary button group"
              onClick={() => addCourse()}
            >
              Add Course
            </Button>
          </Typography>
        </Box>
      </Modal>
      <div className={styles.container}>
        <Sidebar />
        <div className={styles.container}></div>
        <div className={styles.card}>
          <div className={styles.heading}>
            Courses
            <span className={styles.headingRight}>
              <Button
                variant="contained"
                aria-label="outlined primary button group"
                onClick={() => ModalOpen(true)}
              >
                Add
              </Button>
            </span>
          </div>

          <TableContainer sx={{ maxHeight: 550 }} component={Paper}>
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow className={styles.hr}>
                  <TableCell>Course Name</TableCell>
                  <TableCell align="left">Added Time</TableCell>
                  <TableCell align="left">Action</TableCell>
                </TableRow>
              </TableHead>
              {institutions.length === 0 ? (
                <center>
                  <LoadingSpinner />
                </center>
              ) : (
                <TableBody className={styles.body}>
                  {institutions.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.createdAt}</TableCell>
                      <TableCell align="left">
                        <Button>Delete</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default CourseMaster;
