import React from "react";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();

  function navAction(action) {
    console.log(action);
    navigate(`/admin/${action}`);
  }

  return (
    <div className={styles.sidebar}>
      <div className={styles.hed} onClick={() => navAction("coursemaster")}>
        Course Master
      </div>
      <div className={styles.hed} onClick={() => navAction("listtutor")}>
        Tutors
      </div>
      <div
        className={styles.hed}
        onClick={() => navAction("listtutor/pincodewise")}
      >
        Tutors (Pincode Wise)
      </div>
      <div className={styles.hed} onClick={() => navAction("listinstitution")}>
        Institutions
      </div>
    </div>
  );
};

export default Sidebar;
