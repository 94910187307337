import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";

const Navbar = () => {
  return (
    <React.Fragment>
      <nav className={styles.nav}>
        <img
          className={styles.logo}
          src={process.env.PUBLIC_URL + "/logo.png"}
        />
        <div className={styles.menuItems}>
          <Link to="/" className={styles.rbSL}>
            HOME
          </Link>

          <Link to="/seejobs" className={styles.rbSL}>
            FEATURED JOBS
          </Link>
          <Link to="/hireteachers" className={styles.rbSL}>
            HIRE TEACHERS
          </Link>
        </div>
        <Link to="/login" className={styles.rbSL} >
          Login
        </Link>
      </nav>
      <div className={styles.bar} />
    </React.Fragment>
  );
};

export default Navbar;
