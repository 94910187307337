import React from "react";
import { Link, useNavigate } from "react-router-dom";
import style from "./styles.module.css";

const Sidebar = () => {
  return (
    <React.Fragment></React.Fragment>
    // <div className={style.sidebar}>
    //   <ul>
    //     <li>
    //       <Link to="/tutor/dashboard">Tutor Dashboard</Link>
    //     </li>
    //     <li>
    //       <Link to="/tutor/dashboard/see_jobs">Find Jobs</Link>
    //     </li>
    //   </ul>
    // </div>
  );
};

export default Sidebar;
