import React, { useEffect, useState } from "react";
import TutorNavbar from "../TutorNavbar/TutorNavbar";
import styles from "./styles.module.css";
import FeaturedJob from "../FeaturedJobs/FeaturedJob";
import { host, v } from "../../../config";
import Sidebar from "../Sidebar/Sidebar";
import LoadingSpinner from "../../CommonWidget/Loading/LoadingSpinner";

const AppliedJobs = () => {
  const [job, Job] = useState("");
  const [loading, Loading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [search, Search] = useState("");
  const handleJobChange = (value) => {
    Job(value.target.value);
    let newFeaturedJobs = featuresJobs.filter(
      (job) => job.title === value.target.value
    );
    FilteredFeaturedJobs(newFeaturedJobs);
  };

  const featuresJobs = [];

  const [filteredFeaturedJobs, FilteredFeaturedJobs] = useState([]);

  function getJobs() {
    Loading(true);
    fetch(`${host}/api/${v}/tutor/applied_job`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setJobs(data.jobs);
        FilteredFeaturedJobs(data.jobs);
      })
      .catch((error) => {
        // handleClick({ vertical: "top", horizontal: "right" });
        console.error("Error during fetch:", error);
      })
      .finally(() => {
        // navigate("/register/steps/welcome");
        Loading(false);
      });
  }

  useEffect(() => getJobs(), []);

  return (
    <React.Fragment>
      <TutorNavbar title="Find Jobs" />
      <div className={styles.flex}>
        <div>
          <Sidebar />
        </div>
        <div className={`${styles.card}`}>
          <div>
            <div className={styles.apart}>
              <div className={styles.heading}>Jobs Applied</div>
              <div>Found {jobs.length} Jobs</div>
            </div>

            {loading ? (
              <LoadingSpinner />
            ) : (
              filteredFeaturedJobs.map((value) => {
                return (
                  <FeaturedJob
                    imageUrl={
                      "https://www.teachaway.com/sites/default/files/styles/header_image_1024x575/public/job-board-main_0.png?itok=P_hWWo-G"
                    }
                    details={value}
                    appiled={value.isJobApplied}
                  />
                );
              })
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AppliedJobs;
