import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import TutorNavbar from "../TutorNavbar/TutorNavbar";
import {
  Box,
  Button,
  FormControl,
  Modal,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import styles from "./styles.module.css";
import SizedBox from "../../CommonWidget/SizedBox/SizedBox";
import { host, v } from "../../../config";
import Sidebar from "../Sidebar/Sidebar";
import { jwtDecode } from "jwt-decode";
import Skills from "../Skills/Skills";
import LoadingSpinner from "../../CommonWidget/Loading/LoadingSpinner";
import Experience from "../Experience/Experience";
import { DateField } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/en";
import Awards from "../Awards/Awards";
import Achivements from "../Achivements/Achivements";

const TutorProfile = () => {
  const [editPersonalDetails, EditPersonalDetails] = useState(false);
  const inputRef = useRef(null);

  const [skillAddLoading, SkillAddLoading] = useState(false);
  const [newSkill, NewSkill] = useState("");
  const [openSkillModal, OpenSkillModal] = useState(false);

  const [experienceLoading, ExperienceLoading] = useState(false);
  const [newExperienceTitle, NewExperienceTitle] = useState("");
  const [fromDate, FromDate] = useState("");
  const [toDate, ToDate] = useState("");
  const [newExperienceDescription, NewExperienceDescription] = useState("");
  const [openExperienceModal, OpenExperienceModal] = useState(false);

  const [awardLoading, AwardLoading] = useState(false);
  const [newAwardTitle, NewAwardTitle] = useState("");
  const [awardDate, AwardDate] = useState("");
  const [newAwardDescription, NewAwardDescription] = useState("");
  const [openAwardModal, OpenAwardModal] = useState(false);

  const [tutorSkills, TutorSkills] = useState([]);
  const [tutorExperiences, TutorExperiences] = useState([]);
  const [tutorAwards, TutorAwards] = useState([
    {
      name: "Award1",
      date: "02Dec2021",
      description: "abcd",
    },
  ]);
  const [tutorAchivements, TutorAchivements] = useState([
    {
      name: "Award1",
      date: "02Dec2021",
      description: "abcd",
    },
  ]);

  const [profile, Profile] = useState({});
  const [profileImage, setProfileImage] = useState();

  const handleClick = () => {
    inputRef.current.click();
  };

  function getData() {
    try {
      const token = localStorage.getItem("auth");
      const tutorId = jwtDecode(token).tutorId;
      setProfileImage(`${host}/tutor_profile/${tutorId}.png`);

      fetch(`${host}/api/${v}/tutor/profile_data`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          Profile(data.profile);
        })
        .catch((error) => {
          console.error("Error during fetch:", error);
        });
    } catch {}
  }
  useEffect(() => {
    getData();
    getSkills();
    getExperiences();
    getAchivement();
    getAward();
  }, []);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setProfileImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
      try {
        const formData = new FormData();
        formData.append("profileImage", file);

        const headers = {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        };

        const response = await fetch(
          `${host}/api/${v}/tutor/upload_profile_img `,
          {
            method: "POST",
            headers: headers,
            body: formData,
          }
        );

        if (response.ok) {
          if (response.status != true) {
            console.log("Image not uploaded");
          }
        } else {
          console.error("Upload failed:", response.status);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const handlePersonalDetail = (v) => {
    const { name, value } = v.target;
    Profile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  function getSkills() {
    try {
      fetch(`${host}/api/${v}/tutor/skills`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          let skillNames = data.skills.map((skill) => skill.name);
          TutorSkills(skillNames);
          // console.log(skillNames);
        })
        .catch((error) => {
          console.error("Error during fetch:", error);
        });
    } catch {}
  }
  const addNewSkill = () => {
    try {
      SkillAddLoading(true);
      fetch(`${host}/api/${v}/tutor/skills`, {
        method: "POST",
        body: JSON.stringify({
          name: newSkill,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          SkillAddLoading(false);
          OpenSkillModal(false);
          getSkills();
          return response.json();
        })
        .then((data) => {
          // Profile(data.profile);
        })
        .catch((error) => {
          console.error("Error during fetch:", error);
        });
    } catch {}
  };

  function getExperiences() {
    try {
      fetch(`${host}/api/${v}/tutor/experience`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          TutorExperiences(data.experiences);
        })
        .catch((error) => {
          console.error("Error during fetch:", error);
        });
    } catch {}
  }
  const addNewExperience = () => {
    try {
      ExperienceLoading(true);
      fetch(`${host}/api/${v}/tutor/experience`, {
        method: "POST",
        body: JSON.stringify({
          name: newExperienceTitle,
          fromDate: fromDate,
          toDate: toDate,
          description: newExperienceDescription,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          ExperienceLoading(false);
          OpenExperienceModal(false);
          getExperiences();
          return response.json();
        })
        .then((data) => {
          // Profile(data.profile);
        })
        .catch((error) => {
          console.error("Error during fetch:", error);
        });
    } catch {}
  };

  const addNewAchiment = () => {
    try {
      AwardLoading(true);
      fetch(`${host}/api/${v}/tutor/achivement`, {
        method: "POST",
        body: JSON.stringify({
          name: newAwardTitle,
          date: awardDate,
          description: newAwardDescription,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          AwardLoading(false);
          OpenAwardModal(false);
          getAward();
          return response.json();
        })
        .then((data) => {
          // Profile(data.profile);
        })
        .catch((error) => {
          console.error("Error during fetch:", error);
        });
    } catch {}
  };
  function getAchivement() {
    try {
      fetch(`${host}/api/${v}/tutor/achivement`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          TutorAchivements(data.achivements);
        })
        .catch((error) => {
          console.error("Error during fetch:", error);
        });
    } catch {}
  }

  const addNewAward = () => {
    try {
      AwardLoading(true);
      fetch(`${host}/api/${v}/tutor/award`, {
        method: "POST",
        body: JSON.stringify({
          name: newAwardTitle,
          date: awardDate,
          description: newAwardDescription,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          AwardLoading(false);
          OpenAwardModal(false);
          getAward();
          return response.json();
        })
        .then((data) => {
          // Profile(data.profile);
        })
        .catch((error) => {
          console.error("Error during fetch:", error);
        });
    } catch {}
  };
  function getAward() {
    try {
      fetch(`${host}/api/${v}/tutor/award`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          TutorAwards(data.awards);
        })
        .catch((error) => {
          console.error("Error during fetch:", error);
        });
    } catch {}
  }

  const handleEditPersonalDetails = () => {
    try {
      ExperienceLoading(true);
      fetch(`${host}/api/${v}/tutor/profile_data`, {
        method: "POST",
        body: JSON.stringify({
          name: newExperienceTitle,
          fromDate: fromDate,
          toDate: toDate,
          description: newExperienceDescription,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          ExperienceLoading(false);
          OpenExperienceModal(false);
          getExperiences();
          return response.json();
        })
        .then((data) => {
          // Profile(data.profile);
        })
        .catch((error) => {
          console.error("Error during fetch:", error);
        });
    } catch {}
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 12,
    p: 4,
  };
  return (
    <React.Fragment>
      <TutorNavbar title="Profile" />
      <Modal
        open={openSkillModal}
        onClose={() => {}}
        name="Add SKill Modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <div className={styles.right}>{/* <FontAwesomeIcon  /> */}</div>
          </div>
          <Button
            onClick={() => {
              OpenSkillModal(false);
            }}
          >
            Close
          </Button>

          <Typography id="modal-modal-title" variant="h6" component="h2">
            Enter Skill Name
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Skill Name"
            name="name"
            autoComplete="name"
            value={newSkill}
            onChange={(v) => NewSkill(v.target.value)}
            autoFocus
          />
          {skillAddLoading ? (
            <LoadingSpinner />
          ) : (
            <Button onClick={addNewSkill} variant="contained">
              Add
            </Button>
          )}
        </Box>
      </Modal>

      <Modal
        open={openExperienceModal}
        onClose={() => {}}
        name="Add Experience Modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <div className={styles.right}>{/* <FontAwesomeIcon  /> */}</div>
          </div>
          <Button
            onClick={() => {
              OpenExperienceModal(false);
            }}
          >
            Close
          </Button>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Enter Instituion Name
          </Typography>
          <TextField
            margin="normal"
            required
            value={newExperienceTitle}
            fullWidth
            id="name"
            label="Institution Name"
            name="name"
            autoComplete="name"
            onChange={(v) => NewExperienceTitle(v.target.value)}
            autoFocus
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateField
              label="Joining Date"
              value={fromDate}
              onChange={(newValue) => {
                let formattedDate = dayjs(newValue).format("DDMMMYYYY");
                FromDate(formattedDate);
              }}
            />
            <SizedBox width="10px" height="14px" />
            <DateField
              label="Leaving Date"
              value={toDate}
              onChange={(newValue) => {
                let formattedDate = dayjs(newValue).format("DDMMMYYYY");
                ToDate(formattedDate);
              }}
            />
          </LocalizationProvider>
          <SizedBox width="10px" height="18px" />

          <FormControl fullWidth>
            Job Description
            <TextareaAutosize
              aria-label="minimum height"
              minRows={4}
              placeholder="Job Description"
              name={newExperienceDescription}
              onChange={(v) => NewExperienceDescription(v.target.value)}
            />
            <SizedBox width="20px" height="18px" />
          </FormControl>
          <SizedBox width="10px" height="14px" />

          {experienceLoading ? (
            <LoadingSpinner />
          ) : (
            <Button onClick={addNewExperience} variant="contained">
              Add
            </Button>
          )}
        </Box>
      </Modal>

      {/* AwardModal */}
      <Modal
        open={openAwardModal}
        onClose={() => {}}
        name="Add Award Modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <div className={styles.right}>{/* <FontAwesomeIcon  /> */}</div>
          </div>
          <Button
            onClick={() => {
              OpenAwardModal(false);
            }}
          >
            Close
          </Button>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Enter Award Name
          </Typography>
          <TextField
            margin="normal"
            required
            value={newAwardTitle}
            fullWidth
            id="name"
            label="Award Name"
            name="name"
            autoComplete="name"
            onChange={(v) => NewAwardTitle(v.target.value)}
            autoFocus
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateField
              label="Joining Date"
              value={awardDate}
              onChange={(newValue) => {
                let formattedDate = dayjs(newValue).format("DDMMMYYYY");
                AwardDate(formattedDate);
              }}
            />
          </LocalizationProvider>
          <SizedBox width="10px" height="18px" />

          <FormControl fullWidth>
            Award Description
            <TextareaAutosize
              aria-label="minimum height"
              minRows={4}
              placeholder="Award Description"
              name={newAwardDescription}
              onChange={(v) => NewAwardDescription(v.target.value)}
            />
            <SizedBox width="20px" height="18px" />
          </FormControl>
          <SizedBox width="10px" height="14px" />

          {awardLoading ? (
            <LoadingSpinner />
          ) : (
            <Button onClick={addNewAward} variant="contained">
              Add
            </Button>
          )}
        </Box>
      </Modal>
      <div className={styles.main}>
        <div className={styles.body}>
          <div className={styles.flex}>
            <div className={styles.left}>
              <div className={styles.introHr}>Personal Details</div>
              <div className={`${styles.introCard} ${styles.card}`}>
                <div className={styles.apart}>
                  {editPersonalDetails ? (
                    <div />
                  ) : (
                    <Button onClick={() => EditPersonalDetails(true)}>
                      Edit
                    </Button>
                  )}
                </div>
                <SizedBox height="10px" />
                <div className={style.introtext}>
                  <div className={styles.flx}>
                    <div>
                      <div className={styles.aboutHr}>Full Name</div>
                      <input
                        name="name"
                        value={profile.name}
                        onChange={handlePersonalDetail}
                        disabled={!editPersonalDetails}
                        className={styles.inp}
                      />
                    </div>
                    <div>
                      <div className={styles.aboutHr}>Father's Name</div>
                      <input
                        name="gender"
                        value={profile.fatherName}
                        onChange={handlePersonalDetail}
                        disabled={!editPersonalDetails}
                        className={styles.inp}
                      />
                    </div>
                  </div>

                  <SizedBox height="20px" />

                  <div className={styles.flx}>
                    <div>
                      <div className={styles.aboutHr}>Email</div>
                      <input
                        name="email"
                        value={profile.email}
                        onChange={handlePersonalDetail}
                        disabled={!editPersonalDetails}
                        className={styles.inp}
                      />
                    </div>

                    <div>
                      <div className={styles.aboutHr}>Phone Number</div>
                      <input
                        name="phone1"
                        value={profile.phone1}
                        onChange={handlePersonalDetail}
                        disabled={!editPersonalDetails}
                        className={styles.inp}
                      />
                    </div>
                  </div>

                  <SizedBox height="20px" />

                  <div className={styles.flx}>
                    <div>
                      <div className={styles.aboutHr}>Maritial State</div>
                      <input
                        name="maretialState"
                        value={profile.maretialState}
                        onChange={handlePersonalDetail}
                        disabled={!editPersonalDetails}
                        className={styles.inp}
                      />
                    </div>
                    <div>
                      <div className={styles.aboutHr}>Languages Known</div>
                      <input
                        name="gender"
                        value={profile.languages}
                        onChange={handlePersonalDetail}
                        disabled={!editPersonalDetails}
                        className={styles.inp}
                      />
                    </div>
                  </div>

                  <SizedBox height="20px" />

                  <div className={styles.flx}>
                    <div>
                      <div className={styles.aboutHr}>Gender</div>
                      <input
                        name="gender"
                        value={profile.gender}
                        onChange={handlePersonalDetail}
                        disabled={!editPersonalDetails}
                        className={styles.inp}
                      />
                    </div>
                    <div>
                      <div className={styles.aboutHr}>Date of Birth</div>
                      <input
                        name="dob"
                        value={profile.dob}
                        onChange={handlePersonalDetail}
                        disabled={!editPersonalDetails}
                        className={styles.inp}
                      />
                    </div>
                  </div>

                  <SizedBox height="20px" />

                  <div className={styles.flx}>
                    <div>
                      <div className={styles.aboutHr}>Mother Toungue</div>
                      <input
                        name="motherToungue"
                        value={profile.motherToungue}
                        onChange={handlePersonalDetail}
                        disabled={!editPersonalDetails}
                        className={styles.inp}
                      />
                    </div>
                    <div>
                      <div className={styles.aboutHr}>City</div>
                      <input
                        name="city"
                        value={profile.city}
                        onChange={handlePersonalDetail}
                        disabled={!editPersonalDetails}
                        className={styles.inp}
                      />
                    </div>
                  </div>

                  <SizedBox height="20px" />

                  <div className={styles.flx}>
                    <div>
                      <div className={styles.aboutHr}>State</div>
                      <input
                        name="state"
                        value={profile.state}
                        onChange={handlePersonalDetail}
                        disabled={!editPersonalDetails}
                        className={styles.inp}
                      />
                    </div>
                    <div>
                      <div className={styles.aboutHr}>Pincode</div>
                      <input
                        name="pincode"
                        value={profile.pincode}
                        onChange={handlePersonalDetail}
                        disabled={!editPersonalDetails}
                        className={styles.inp}
                      />
                    </div>
                  </div>

                  <SizedBox height="20px" />

                  <div className={styles.flex}>
                    <div>
                      <div className={styles.aboutHr}>Counrty</div>
                      <input
                        name="pincode"
                        value="India"
                        onChange={handlePersonalDetail}
                        disabled={true}
                        className={styles.inp}
                      />

                      <SizedBox height="30px" />
                      {editPersonalDetails ? (
                        <div className={styles.flex}>
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => EditPersonalDetails(false)}
                          >
                            Cancel
                          </Button>
                          <SizedBox width="10px" />
                          <Button
                            variant="contained"
                            onClick={handleEditPersonalDetails}
                          >
                            Save
                          </Button>
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                    <SizedBox width="40px" />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.right}>
              <div
                className={`${styles.card}`}
                style={{ alignItems: "center" }}
              >
                <div className={styles.introHr}>Profile</div>
                {profileImage ? (
                  <img
                    src={profileImage}
                    alt="Profile"
                    className={styles.avatar}
                  />
                ) : (
                  <div className={styles.avatar}>No Profile Image</div>
                )}
                <div style={{ height: "40px" }} />

                <div
                  className={styles.upld_btn}
                  onClick={handleClick}
                  style={{ cursor: "pointer" }}
                >
                  Upload new Profile
                  <input
                    type="file"
                    accept="image/*"
                    ref={inputRef}
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                </div>
              </div>

              <div style={{ height: "40px" }} />

              <div className={styles.introHr}>Skills</div>
              <div className={`${styles.card}`}>
                <div className={styles.sCard}>
                  {tutorSkills.length === 0
                    ? "No Skill Added yet!"
                    : tutorSkills.map((v, i) => <Skills key={i} skill={v} />)}
                </div>

                <button
                  type="text"
                  onClick={() => {
                    OpenSkillModal(!openSkillModal);
                  }}
                  className={styles.button}
                >
                  Add New
                </button>
              </div>

              <SizedBox height="20px" />
              <div className={`${styles.card}`}>
                <div className={styles.introHr}>Video Introduction</div>
                <SizedBox height="10px" />
                <center> Comming Soon</center>
              </div>
            </div>
          </div>

          <SizedBox height="50px" />
        </div>

        <div className={styles.body}>
          <div className={`${styles.card}`}>
            <div className={styles.apart}>
              <div className={styles.introHr}>Experience</div>
              <Button
                type="text"
                onClick={() => {
                  OpenExperienceModal(!openExperienceModal);
                }}
                variant="contained"
              >
                Add
              </Button>
            </div>
            <SizedBox height="10px" />
            {tutorExperiences.length === 0
              ? "No Experience Added yet!"
              : tutorExperiences.map((v, i) => (
                  <Experience key={i} experience={tutorExperiences[i]} />
                ))}
          </div>

          <SizedBox height="50px" />

          <div className={`${styles.card}`}>
            <div className={styles.apart}>
              <div className={styles.introHr}>Awards</div>
              <Button
                type="text"
                onClick={() => {
                  OpenAwardModal(!openAwardModal);
                }}
                variant="contained"
              >
                Add
              </Button>
            </div>
            <SizedBox height="10px" />
            {tutorAwards.length === 0
              ? "No Awards Added yet!"
              : tutorAwards.map((v, i) => (
                  <Awards key={i} award={tutorAwards[i]} />
                ))}
          </div>

          <SizedBox height="50px" />

          <div className={` ${styles.card}`}>
            <div className={styles.apart}>
              <div className={styles.introHr}>Achivements</div>
              <Button
                type="text"
                onClick={() => {
                  OpenExperienceModal(!openExperienceModal);
                }}
                variant="contained"
              >
                Add
              </Button>
            </div>
            <SizedBox height="10px" />
            {tutorAchivements.length === 0
              ? "No Awards Added yet!"
              : tutorAchivements.map((v, i) => (
                  <Achivements key={i} achivement={tutorAchivements[i]} />
                ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TutorProfile;
