import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import InstitutionNavbar from "../InstitutionNavbar/InstitutionNavbar";
import styles from "./styles.module.css";
import Sidebar from "../Sidebar/Sidebar";
import SizedBox from "../../Widgets/SizedBox";
import LoadingSpinner from "../../CommonWidget/Loading/LoadingSpinner";
import { host, v } from "../../../config";
import ExpiredJob from "../ExpiredJob/ExpiredJob";

const ExpiredJobs = () => {
  const [loading, Loading] = useState(false);
  const [jobs, setJobs] = useState([]);

  useEffect(() => getJobs(), []);
  function getJobs() {
    Loading(true);
    fetch(`${host}/api/${v}/institution/expiredjob`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setJobs(data.jobs);
      })
      .catch((error) => {
        // handleClick({ vertical: "top", horizontal: "right" });
        console.error("Error during fetch:", error);
      })
      .finally(() => {
        // navigate("/register/steps/welcome");
        Loading(false);
      });
  }

  return (
    <React.Fragment>
      <InstitutionNavbar title="Expired / Cancelled Jobs" />
      <div className={styles.flex}>
        <div>
          <Sidebar />
        </div>
        <div className={styles.card}>
          {/* <div className={styles.topbox}> */}
          <div className={`${styles.flex} ${styles.spaceBetween}`}>
            <div className={styles.heading}>Closed Jobs</div>
            <div>{"Found : " + jobs.length + " Job"}</div>
          </div>
          <SizedBox height="26px" />
          {loading ? (
            <div className={styles.noJobs}>
              <LoadingSpinner />
            </div>
          ) : jobs.length ? (
            jobs.map((value, i) => {
              return (
                <ExpiredJob
                  key={i}
                  srNo={i + 1}
                  imageUrl={
                    "https://www.teachaway.com/sites/default/files/styles/header_image_1024x575/public/job-board-main_0.png?itok=P_hWWo-G"
                  }
                  title={value.name}
                  description={value.description}
                  pincode={value.pincode}
                  id={value.jobId}
                  closedReason={value.closeReason}
                  city={"MTR"}
                  state={"U.P."}
                  closedDate={value.closedAt}
                  isActive={false}
                />
              );
            })
          ) : (
            <div className={styles.noJobs}>No Jobs Expired yet!</div>
          )}
        </div>
        {/* </div> */}
      </div>
    </React.Fragment>
  );
};

export default ExpiredJobs;
