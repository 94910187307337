import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import AdminNavbar from "../AdminNavbar/AdminNavbar";
import Sidebar from "./Sidebar/Sidebar";

const AdminDashbaord = () => {
  const navigate = useNavigate();

  async function Logout() {
    localStorage.clear();
    navigate("/login");
  }

  function navAction(action) {
    console.log(action);
    navigate(`/admin/${action}`);
  }

  return (
    <React.Fragment>
      <AdminNavbar />
      <div className={styles.flex}>
        <Sidebar />
        <h2>Admin Dashboard</h2>
        <br />
        <p>Please Use Side bar to navigate </p>
      </div>
    </React.Fragment>
  );
};

export default AdminDashbaord;
