import React, { useEffect, useState } from "react";
import TutorNavbar from "../TutorNavbar/TutorNavbar";
import Sidebar from "../Sidebar/Sidebar";
import styles from "./styles.module.css";
import { host, v } from "../../../config";

const TutorDashbaord = () => {
  const [loading, Loading] = useState(false);
  const [jobCount, JobCount] = useState(0);
  useEffect(() => {
    function getJobsCount() {
      Loading(true);
      fetch(`${host}/api/${v}/tutor/jobcount`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          JobCount(data.count);
        })
        .catch((error) => {
          // handleClick({ vertical: "top", horizontal: "right" });
          console.error("Error during fetch:", error);
        })
        .finally(() => {
          // navigate("/register/steps/welcome");
          Loading(false);
        });
    }
    getJobsCount();
  }, []);
  return (
    <React.Fragment>
      <TutorNavbar title="Tutor dashboard" />
      <div style={{ display: "flex" }}>
        <Sidebar />
        <div className={styles.dash}>
          <h1>Tutor Dashbaord</h1>
          <div
            className={styles.dashComp}
          >
            <h3> Jobs Applied </h3>
            <h4>{jobCount + " Job(s)"}</h4>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TutorDashbaord;
