import React, { useState } from "react";
import Navbar from "../../CommonWidget/Navbar/Navbar";
import { Button, Container, TextField } from "@mui/material";
import SizedBox from "../../Widgets/SizedBox";
import LoadingSpinner from "../../CommonWidget/Loading/LoadingSpinner";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { host, v } from "../../../config";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";

const AdminLoginPage = () => {
  const navigate = useNavigate();
  const [loading, Loading] = useState(false);
  const [email, Email] = useState("");
  const [password, Password] = useState("");

  function handleLogin() {
    Loading(true);

    fetch(`${host}/api/${v}/visitor/adminlogin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: email,
        password: password,
      }),
    })
      .then((response) => {
        if (response.status === 400) {
          console.log(45);
          handleClick({ vertical: "top", horizontal: "right" });
        }
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.msg === true) {
          navigate("/admin/dashboard");
          if (data.token) {
            localStorage.setItem("auth", data.token);
          }
        }
      })
      .catch((error) => {
        handleClick({ vertical: "top", horizontal: "right" });
        console.error("Error during fetch:", error);
      })
      .finally(() => {
        Loading(false);
      });
  }
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  function handleClick(newState) {
    setState({ ...newState, open: true });
  }
  return (
    <React.Fragment>
      <Navbar />

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={4000}
      >
        <Alert severity="secondary" variant="filled" sx={{ width: "100%" }}>
          Invalid Credentials !!!
        </Alert>
      </Snackbar>
      <div className={styles.body}>
        <Container className={styles.loginCon} component="main" maxWidth="xs">
          <center>
            <h1>Login as ADMIN</h1>
          </center>

          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Username"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(v) => Email(v.target.value)}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            label="Password"
            name="password"
            value={password}
            onChange={(v) => Password(v.target.value)}
            autoComplete="email"
          />
          {loading ? (
            <LoadingSpinner />
          ) : (
            <Button onClick={() => handleLogin()} variant="contained">
              Login
            </Button>
          )}
          <SizedBox height="40px" />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AdminLoginPage;
