import {useNavigate} from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import { useEffect } from 'react';

function VisitorRoute(prop){
    const {Component}= prop;
    const navigate = useNavigate();
        function auth(){
            const token = localStorage.getItem('auth');
            if (token) {
                try {
                  const decoded = jwtDecode(token);
                  let {tutorId, institutionId, adminId} = decoded;
                  if (decoded.exp < Date.now() / 1000) {
                    localStorage.removeItem('auth');
                    navigate('/')
                  }
                  if(tutorId){
                    navigate('/tutor/dashboard')
                  }
                  if(institutionId){
                    navigate('/institution/dashboard')
                  }
                  if(adminId){
                    navigate('/admin/dashboard')
                  }
                } catch (error) {
                  console.error('Error decoding token:', error);
                }
              }else{
           
              }
        }
        useEffect(()=>auth(),[]);
        auth();
    return (<Component />)
}

export default VisitorRoute;