import React from "react";
import styles from "./styles.module.css";

const Awards = ({ award }) => {
  return (
    <div className={styles.card}>
      <div className={styles.apart}>
        <div className={styles.title}>{award.name}</div>
        <div className={styles.date}>{award.date}</div>
      </div>
      <div className={styles.description}>{award.description}</div>
    </div>
  );
};

export default Awards;
