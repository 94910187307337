import React, { useEffect, useState } from "react";
import InstitutionNavbar from "../InstitutionNavbar/InstitutionNavbar";
import styles from "./styles.module.css";
import Jobs from "../Jobs/Jobs";
import Sidebar from "../Sidebar/Sidebar";
import LoadingSpinner from "../../CommonWidget/Loading/LoadingSpinner";
import { host, v } from "../../../config";
import { useNavigate } from "react-router-dom";

const JobDetails = (props) => {
  const [loading, Loading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      Loading(true);
      const path = window.location.pathname;
      const parts = path.split("/");
      const postIdFromUrl = parts[parts.length - 1];
      fetch(`${host}/api/${v}/institution/jobposts/${postIdFromUrl}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setJobs(data.jobs);
        })
        .catch((error) => {
          // handleClick({ vertical: "top", horizontal: "right" });
          console.error("Error during fetch:", error);
        })
        .finally(() => {
          // navigate("/register/steps/welcome");
          Loading(false);
        });
    };

    fetchData();
  }, []);

  return (
    <React.Fragment>
      <InstitutionNavbar title="Jobs Detail" />
      <div className={styles.flex}>
        <div>
          <Sidebar />
        </div>
        <div className={styles.all}>
          <div className={styles.card}>
            <div className={`${styles.flex} ${styles.spaceBetween}`}>
              <div className={styles.heading}>Tutors Applied on your Job</div>
              {jobs.length + " Tutor(s) Applied"}
            </div>

            {loading ? (
              <div className={styles.noJobs}>
                <LoadingSpinner />
              </div>
            ) : jobs.length ? (
              jobs.map((value, i) => {
                return (
                  <div onClick={() => navigate("/institution/jobapplicants")}>
                    <Jobs
                      key={i}
                      srNo={i + 1}
                      imageUrl={`${host}/tutor_profile/${value.tutorId}.png`}
                      details={value}
                      title={value.tutorName}
                      pincode={value.pincode}
                      description={value.email}
                    />
                  </div>
                );
              })
            ) : (
              <div className={styles.noJobs}>No Tutor Applied yet!</div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default JobDetails;
