import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import styles from "./style.module.css";

const AdminNavbar = () => {
  const navigate = useNavigate();
  const [adminName, AdminName] = useState();

  function getName() {
    try {
      const token = localStorage.getItem("auth");
      const name = jwtDecode(token).name;
      console.log(jwtDecode(token));
      if (name) {
        AdminName(name);
      }
    } catch {}
  }
  useEffect(() => getName(), []);
  async function Logout() {
    localStorage.clear();
    navigate("/");
  }
  return (
    <div className={styles.nav}>
      <div className={styles.title}>Admin Dashboard</div>
      <div className={styles.rightDiv}>
        <Button variant="text">{adminName + " (Admin)"}</Button>
        <Button
          variant="contained"
          color="error"
          aria-label="outlined primary button group"
          onClick={() => Logout()}
        >
          Logout
        </Button>
      </div>
    </div>
  );
};

export default AdminNavbar;
