import React, { useState } from "react";
import Navbar from "../../../CommonWidget/Navbar/Navbar";
import {
  Button,
  Container,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import SizedBox from "../../../Widgets/SizedBox";
import LoadingSpinner from "../../../CommonWidget/Loading/LoadingSpinner";
import { host, v } from "../../../../config";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";

const Step1 = () => {
  const navigate = useNavigate();
  const [loading, Loading] = useState(false);
  const [name, Name] = useState("");
  const [email, Email] = useState("");
  const [password, Password] = useState("");

  function handleStep1() {
    navigate("/register/steps/interests");
    // Loading(true);

    // fetch(`${host}/api/${v}/register`, {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({
    //     name: name,
    //     email: email,
    //     password: password,
    //   }),
    // })
    //   .then((response) => {
    //     if (response.status === 400) {
    //       console.log(45);
    //       // handleClick({ vertical: "top", horizontal: "right" });
    //     }
    //     if (!response.ok) {
    //       throw new Error(`HTTP error! Status: ${response.status}`);
    //     }
    //     return response.json();
    //   })
    //   .then((data) => {
    //     if (data.status === true) {
    //       navigate("/register/steps/welcome");
    //     }
    //   })
    //   .catch((error) => {
    //     // handleClick({ vertical: "top", horizontal: "right" });
    //     console.error("Error during fetch:", error);
    //   })
    //   .finally(() => {
    //     Loading(false);
    //   });
  }

  return (
    <React.Fragment>
      <Navbar />
      <div className={styles.body}>
        <h3>Welcome Buddy</h3>
        Let's get started. Complete your Profile
        <br />
        Get ready to explore jobs online!
        <Container
          className={`${styles.reg} XCon`}
          component="main"
          maxWidth="xs"
        >
          <SizedBox height="20px" />
          <div className={styles.left}>Which job title fits you best?</div>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={name}
              // onChange={handleChange}
            >
              <MenuItem value={10}>Licensed/Certified Teacher</MenuItem>
              <MenuItem value={20}>University Graduate - Not Licensed</MenuItem>
              <MenuItem value={30}>ESL Instructor</MenuItem>
              <MenuItem value={20}>University Graduate - Not Licensed</MenuItem>
              <MenuItem value={30}>Director/Principal</MenuItem>
              <MenuItem value={20}>College/University Faculty</MenuItem>
              <MenuItem value={30}>School Health and Welfare Staff</MenuItem>
              <MenuItem value={20}>Librarian</MenuItem>
              <MenuItem value={30}>Office/Administration Staff</MenuItem>
            </Select>

            <div className={styles.left}>Do you have teaching Licence?</div>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={email}
              // onChange={handleChange}
            >
              <MenuItem value={10}>Yes</MenuItem>
              <MenuItem value={20}>No</MenuItem>
            </Select>

            <div className={styles.left}>Teaching Preference</div>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={email}
              // onChange={handleChange}
            >
              <MenuItem value={20}>Online</MenuItem>
              <MenuItem value={10}>Offline</MenuItem>
            </Select>

            <div className={styles.left}>
              PLease Write your Subject/Subjects here.
            </div>
            <Input />

            <div className={styles.left}>
              Do you have a teaching license or credential?*
            </div>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={email}
              // onChange={handleChange}
            >
              <MenuItem value={10}>Yes</MenuItem>
              <MenuItem value={20}>No</MenuItem>
            </Select>
          </FormControl>

          <SizedBox height="20px" />
          {loading ? (
            <LoadingSpinner />
          ) : (
            <Button onClick={() => handleStep1()} variant="contained">
              Next
            </Button>
          )}
          <SizedBox height="30px" />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Step1;
