import React, { useState } from "react";
import Navbar from "../../../CommonWidget/Navbar/Navbar";
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import SizedBox from "../../../Widgets/SizedBox";
import LoadingSpinner from "../../../CommonWidget/Loading/LoadingSpinner";
import { host, v } from "../../../../config";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";

const Step5 = () => {
  const navigate = useNavigate();
  const [loading, Loading] = useState(false);
  const [name, Name] = useState("");
  const [email, Email] = useState("");
  const [password, Password] = useState("");

  function handleStep5() {
    navigate("/login");
    // Loading(true);

    // fetch(`${host}/api/${v}/register`, {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({
    //     name: name,
    //     email: email,
    //     password: password,
    //   }),
    // })
    //   .then((response) => {
    //     if (response.status === 400) {
    //       console.log(45);
    //       // handleClick({ vertical: "top", horizontal: "right" });
    //     }
    //     if (!response.ok) {
    //       throw new Error(`HTTP error! Status: ${response.status}`);
    //     }
    //     return response.json();
    //   })
    //   .then((data) => {
    //     if (data.status === true) {
    //       navigate("/register/steps/welcome");
    //     }
    //   })
    //   .catch((error) => {
    //     // handleClick({ vertical: "top", horizontal: "right" });
    //     console.error("Error during fetch:", error);
    //   })
    //   .finally(() => {
    //     Loading(false);
    //   });
  }

  return (
    <React.Fragment>
      <Navbar />
      <div className={styles.body}>
        <h1>Welcome</h1>
        <SizedBox height="10px" />
        <Container
          className={`${styles.reg} XCon`}
          component="main"
          maxWidth="xs"
        >
          <SizedBox height="20px" />
          We are happy to onboard you
          <SizedBox height="20px" />
          {loading ? (
            <LoadingSpinner />
          ) : (
            <Button onClick={() => handleStep5()} variant="contained">
              Go to Login
            </Button>
          )}
          <SizedBox height="30px" />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Step5;
