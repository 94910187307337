import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import InstitutionNavbar from "../InstitutionNavbar/InstitutionNavbar";
import styles from "./styles.module.css";
import Jobs from "../Jobs/Jobs";
import Sidebar from "../Sidebar/Sidebar";
import SizedBox from "../../Widgets/SizedBox";
import LoadingSpinner from "../../CommonWidget/Loading/LoadingSpinner";
import { host, v } from "../../../config";

const InstitutionPostJobs = () => {
  const [loading, Loading] = useState(false);
  const [jobs, setJobs] = useState([]);

  useEffect(() => getJobs(), []);
  function getJobs() {
    Loading(true);
    fetch(`${host}/api/${v}/institution/job`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const jobsData = data.jobs.map((result) => ({
          ...result,
          isSelected: false,
        }));
        // console.log(jobsData);

        setJobs(jobsData);
      })
      .catch((error) => {
        // handleClick({ vertical: "top", horizontal: "right" });
        console.error("Error during fetch:", error);
      })
      .finally(() => {
        // navigate("/register/steps/welcome");
        Loading(false);
      });
  }

  const handleBoxChange = (id, value) => {
    let tempA = [...jobs];
    let tempAr = tempA.find((job) => job.jobId === id);
    tempAr.isSelected = value;
    setJobs([...tempA]);
  };
  const handleJobDelete = (id) => {
    getJobs();
  };

  const handleCloseSelectedJobs = () => {
    // console.log(jobs);
    const selectedJobs = jobs.filter((job) => job.isSelected === true);
    const jobIdArray = selectedJobs.map((job) => job.jobId);
    fetch(`${host}/api/${v}/institution/close_multiple_job`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
      body: JSON.stringify({ jobIds: jobIdArray, reason: "Multiple Close" }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        if (response.status === 200) {
          // handleClose();
          alert("Job Closed Successfull !");
          getJobs();
        }
        return response.json();
      })
      .then((data) => {
        // JobsExpired(data.jobs.length);
      })
      .catch((error) => {
        // handleClick({ vertical: "top", horizontal: "right" });
        console.error("Error during fetch:", error);
      })
      .finally(() => {
        // navigate("/register/steps/welcome");
        // LoadingCloseJob(false);
      });
  };

  const handleCloseAllJobs = () => {
 let ans =   window.confirm('Are you Sure to Close All Jobs ?')
 if(ans===false){
return;
 }

    fetch(`${host}/api/${v}/institution/close_all_job`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        if (response.status === 200) {
          // handleClose();
          alert("Job Closed Successfull !");
          getJobs();
        }
        return response.json();
      })
      .then((data) => {
        // JobsExpired(data.jobs.length);
      })
      .catch((error) => {
        // handleClick({ vertical: "top", horizontal: "right" });
        console.error("Error during fetch:", error);
      })
      .finally(() => {
        // navigate("/register/steps/welcome");
        // LoadingCloseJob(false);
      });
  };

  return (
    <React.Fragment>
      <InstitutionNavbar title="Jobs Posted by you" />
      <div className={styles.flex}>
        <div>
          <Sidebar />
        </div>
        <div className={styles.card}>
          <div className={styles.topbox}>
            <div className={`${styles.flex} ${styles.spaceBetween}`}>
              <div className={styles.heading}>Jobs Posted by You</div>
              <div>
                {'Found : ' + jobs.length + ' Job'}
              </div>
            </div>
            <SizedBox height="26px" />
            {loading ? (
              <div className={styles.noJobs}>
                <LoadingSpinner />
              </div>
            ) : jobs.length ? (
              <React.Fragment>
                <div style={{ display: "flex" }}>
                  <Button variant="contained" onClick={handleCloseSelectedJobs}>
                    Close Selected Jobs
                  </Button>
                  <SizedBox width="10px" />
                  <Button variant="contained" onClick={handleCloseAllJobs}>
                    Close All Jobs
                  </Button>
                </div>

                {jobs.map((value, i) => {
                  return (
                    <Jobs
                      key={i}
                      srNo={i + 1}
                      isSelected={value.isSelected}
                      imageUrl={
                        "https://www.teachaway.com/sites/default/files/styles/header_image_1024x575/public/job-board-main_0.png?itok=P_hWWo-G"
                      }
                      title={value.name}
                      description={value.description}
                      pincode={value.pincode}
                      member3={"This is a short description of the card."}
                      id={value.jobId}
                      isActive={true}
                      postingDate = {value.createdAt}
                      onJobClose={handleJobDelete}
                      onCheckBoxChange={handleBoxChange}
                    />
                  );
                })}
              </React.Fragment>
            ) : (
              <div className={styles.noJobs}>No Jobs Posted yet!</div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InstitutionPostJobs;
