import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import InstitutionNavbar from "../InstitutionNavbar/InstitutionNavbar";
import { Box, Button, Modal, Typography } from "@mui/material";
import styles from "./styles.module.css";
import SizedBox from "../../CommonWidget/SizedBox/SizedBox";
import { jwtDecode } from "jwt-decode";
import { host, v } from "../../../config";

const InstitutionProfile = () => {
  const navigate = useNavigate();
  const [open, Open] = useState(false);
  const [instid, InstId] = useState("");

  const inputRef = useRef(null);
  const [profile, Profile] = useState({});

  const [profileImage, setProfileImage] = useState();

  function getData() {
    try {
      const token = localStorage.getItem("auth");
      const insid = jwtDecode(token).institutionId;
      InstId(insid);
      setProfileImage(`${host}/institution_profile/${insid}.png`);

      fetch(`${host}/api/${v}/institution/profile_data`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          Profile(data.profile);
        })
        .catch((error) => {
          console.error("Error during fetch:", error);
        });
    } catch {}
  }
  useEffect(() => getData(), []);

  async function Logout() {
    localStorage.clear();
    navigate("/login");
  }

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setProfileImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
      try {
        const formData = new FormData();
        formData.append("profileImage", file);

        const headers = {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        };

        const response = await fetch(
          `${host}/api/${v}/institution/upload_profile_img `,
          {
            method: "POST",
            headers: headers,
            body: formData,
          }
        );

        if (response.ok) {
          navigate();
        } else {
          console.error("Upload failed:", response.status);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 12,
    p: 4,
  };
  return (
    <React.Fragment>
      <InstitutionNavbar />
      <Modal
        open={open}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <div className={styles.left}>d</div>
            <div className={styles.right}>{/* <FontAwesomeIcon  /> */}</div>
          </div>
          <Button
            onClick={() => {
              Open(false);
            }}
          >
            Close
          </Button>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal>
      <div className={styles.body}>
        <SizedBox height="30px" />
        <div className={styles.flx}>
          <div className={styles.left}>
            <div className={styles.card}>
              <div className={styles.introHr}>About your Institution</div>
              <div className={styles.flex}>
                <div className={styles.flx}>
                  <div className={styles.half}>
                    <div className={styles.aboutHr}>Institution Name</div>
                    <div className={styles.input}> {profile.name} </div>
                  </div>
                  <div className={styles.half}>
                    <div className={styles.aboutHr}>Phone Number</div>
                    <div className={styles.input}>{profile.phone}</div>
                  </div>
                </div>

                <div className={styles.flx}>
                  <div className={styles.half}>
                    <div className={styles.aboutHr}>Email</div>
                    <div className={styles.input}> {profile.email} </div>
                  </div>
                  <div className={styles.half}>
                    <div className={styles.aboutHr}>Registered Person Name</div>
                    <div className={styles.input}>{profile.contactPerson}</div>
                  </div>
                </div>

                <div className={styles.flx}>
                  <div className={styles.half}>
                    <div className={styles.aboutHr}>Address</div>
                    <div className={styles.input}> {profile.address} </div>
                  </div>
                  <div className={styles.half}>
                    <div className={styles.aboutHr}>Pincode</div>
                    <div className={styles.input}>{profile.pincode}</div>
                  </div>
                </div>

                <div className={styles.pd}>
                  <div className={styles.half}>
                    <div className={styles.aboutHr}>Country</div>
                    <div className={styles.input}> India </div>
                  </div>
                </div>
              </div>

              <SizedBox height="40px" />

              <div className={styles.introHr}>Institution Overview</div>
              <div className={styles.pd}>
                <div className={styles.aboutHr}>About</div>
                <div className={styles.input}>
                  Mount Hill Academy was founded in 1998 with a vision to
                  provide quality education to students from all backgrounds.
                  Over the years, it has grown into a premier institution with
                  multiple branches across India, offering world-class
                  educational programs and a nurturing environment for learning.
                </div>
              </div>

              <div className={styles.flx}>
                <div className={styles.half}>
                  <div className={styles.aboutHr}>Sector</div>
                  <div className={styles.input}> Private </div>
                </div>
                <div className={styles.half}>
                  <div className={styles.aboutHr}>Indrustory</div>
                  <div className={styles.input}>Education</div>
                </div>
              </div>

              <div className={styles.flx}>
                <div className={styles.half}>
                  <div className={styles.aboutHr}>S</div>
                  <div className={styles.input}> Private </div>
                </div>
                <div className={styles.half}>
                  <div className={styles.aboutHr}>Indrustory</div>
                  <div className={styles.input}>Education</div>
                </div>
              </div>

              <div className={styles.pd}>
                <div className={styles.aboutHr}>History</div>
                <div className={styles.input}>
                  Mount Hill Academy was founded in 1998 with a vision to
                  provide quality education to students from all backgrounds.
                  Over the years, it has grown into a premier institution with
                  multiple branches across India, offering world-class
                  educational programs and a nurturing environment for learning.
                </div>
              </div>

              <div className={styles.pd}>
                <div className={styles.aboutHr}>Location</div>
                <div className={styles.input}>Banglore</div>
              </div>

              <div className={styles.pd}>
                <div className={styles.aboutHr}>Services & Products</div>
                <div className={styles.input}>
                  <ul>
                    <li> Primary and Secondary Education </li>
                    <li> Extra-curricular Activities(Sports, Arts, Music) </li>
                    <li>Career Counseling and Guidance Advanced</li>
                    <li>Placement and Specialized Training Programs</li>
                  </ul>
                </div>
              </div>

              <SizedBox height="40px" />
              <div className={styles.introHr}>Institution Overview</div>
              <div className={styles.pd}>
                <div className={styles.aboutHr}>Position we are offering</div>
                <div className={styles.input}>
                  <ul>
                    <li> Academic Roles: Teachers, Subject Experts</li>
                    <li>Administrative Roles: Office Assistants, HR</li>
                    <li>Support Staff: IT Technicians, Janitors</li>
                  </ul>
                </div>
              </div>

              <div className={styles.flx}>
                <div className={styles.half}>
                  <div className={styles.aboutHr}>Locations</div>
                  <div className={styles.input}>New Delhi, Mumbai, Chennai</div>
                </div>
                <div className={styles.half}>
                  <div className={styles.aboutHr}>Job Types</div>
                  <div className={styles.input}>
                    Full-Time, Part-Time, Temporary
                  </div>
                </div>
              </div>

              <SizedBox height="40px" />

              <div className={styles.introHr}>Why Work with Us?</div>
              <div className={styles.pd}>
                <div className={styles.aboutHr}>
                  Working at {profile.name} comes with a range of benefits:
                </div>
                <div className={styles.input}>
                  <ul>
                    <li>
                      {" "}
                      Flexible Hours: We offer flexible scheduling to
                      accommodate your needs.
                    </li>
                    <li>
                      Professional Development: Access to workshops,
                      certifications, and growth opportunities.
                    </li>
                    <li>
                      Health Benefits: Comprehensive health insurance for you
                      and your family.
                    </li>
                    <li>
                      Work-Life Balance: Encouraging a healthy work-life balance
                      with ample time off and vacation days.
                    </li>
                  </ul>
                </div>
              </div>

              <SizedBox height="40px" />

              <div className={styles.introHr}>Application Proces</div>
              <div className={styles.pd}>
                <div className={styles.aboutHr}>
                  Working at {profile.name} comes with a range of benefits:
                </div>
                <div className={styles.input}>
                  Interested candidates can apply through our careers page:
                  tutorplatter.com/seejobs Follow the instructions to submit
                  your application and upload your resume. Alternatively, you
                  can email your CV to jobs@mounthillacademy.com.
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className={`${styles.card} ${styles.flx_ver}`}>
              <div className={styles.introHr}>Institution Profile</div>
              {/* <div>
                <Button
                  type="text"
                  onClick={() => {
                    Open(!open);
                  }}
                >
                  Edit
                </Button>
              </div> */}
              {profileImage ? (
                <img
                  src={profileImage}
                  alt="Profile"
                  className={styles.avatar}
                />
              ) : (
                <div className={styles.avatar}>No Profile Image</div>
              )}
              <div
                className={styles.avatar_btn}
                style={{
                  cursor: "pointer",
                }}
                onClick={handleClick}
              >
                Upload new profile
                <input
                  type="file"
                  accept="image/*"
                  ref={inputRef}
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />
              </div>
            </div>

            <SizedBox height="20px" />
            <div className={`${styles.introCard} ${styles.card}`}>
              <div className={styles.introHr}>Plane Details</div>
              <SizedBox height="10px" />
              <div className={styles.input}>Rs. {profile.annual_fee} Plane</div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InstitutionProfile;
