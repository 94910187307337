import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./Components/BeforeLogin/LandingPage/LandingPage";
import TutorLogin from "./Components/BeforeLogin/TutorLogin/TutorLogin";
import SeeJobs from "./Components/BeforeLogin/SeeJobs/SeeJobs";
import HireTeachers from "./Components/BeforeLogin/HireTeachers/HireTeachers";
import Step1 from "./Components/BeforeLogin/Register/Step1/Step1";
import Step2 from "./Components/BeforeLogin/Register/Step2/Step2";
import Step3 from "./Components/BeforeLogin/Register/Step3/Step3";
import Step4 from "./Components/BeforeLogin/Register/Step4/Step4";
import SalesTeam from "./Components/BeforeLogin/SalesTeam/SalesTeam";
import TutorDashbaord from "./Components/AfterTutorLogin/TutorDashbaord/TutorDashbaord";
import TutorProfile from "./Components/AfterTutorLogin/TutorProfile/TutorProfile";
import Step5 from "./Components/BeforeLogin/Register/Step5/Step5";
import TutorRegister from "./Components/BeforeLogin/Register/TutorRegistration/TutorRegister";
import RegisterOption from "./Components/BeforeLogin/Register/RegisterOption";
import AdminLoginPage from "./Components/BeforeLogin/AdminLogin/AdminLoginPage";
import AdminDashbaord from "./Components/AfterAdminLogin/AdminDashbaord/AdminDashbaord";
import ListTutor from "./Components/AfterAdminLogin/AdminDashbaord/ListTutor/ListTutor";
import ListInstitution from "./Components/AfterAdminLogin/AdminDashbaord/ListInstitute/ListInstitutes";
import SeeJobsPosts from "./Components/AfterTutorLogin/SeeJobPosts/SeeJobsPosts";
import PinCodeWise from "./Components/AfterAdminLogin/AdminDashbaord/ListTutor/pinCodeWIse/PinCodeWise";
import CourseMaster from "./Components/AfterAdminLogin/AdminDashbaord/CourseMaster/CourseMaster";
import LoginPage from "./Components/BeforeLogin/LoginPage/LoginPage";
import InstitutionLogin from "./Components/BeforeLogin/InstitutionLogin/InstitutionLogin";
import InstitutionDashbaord from "./Components/AfterInstitutionLogin/InstitutionDashbaord/InstitutionDashbaord";
import InstitutionPostJobs from "./Components/AfterInstitutionLogin/JobPosts/InstitutionPostJobs";
import PostNewJob from "./Components/AfterInstitutionLogin/PostNewJob/PostNewJob";
import TutorRoute from "./PrivateRoutes/TutorRoute";
import VisitorRoute from "./PrivateRoutes/VisitorRoute";
import InstitutionRoute from "./PrivateRoutes/InstitutionRoute";
import AdminRoute from "./PrivateRoutes/AdminRoute";
import JobDetails from "./Components/AfterInstitutionLogin/JobDetails/JobDetails";
import ExpiredJobs from "./Components/AfterInstitutionLogin/ExpiredJobs/InstitutionExpiredJobs";
import InstitutionProfile from "./Components/AfterInstitutionLogin/InstitutionProfile/InstitutionProfile";
import AppliedJobs from "./Components/AfterTutorLogin/AppliedJobs/AppliedJobs";
import JobApplicantProfile from "./Components/AfterInstitutionLogin/JobApplicantProfile/JobApplicantProfile";
import TutorForgetPass from "./Components/BeforeLogin/TutorLogin/TutorForgetPass";
import "./index.css";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<VisitorRoute Component={LandingPage} />} />
        <Route path="/login" element={<VisitorRoute Component={LoginPage} />} />
        <Route path="/tutorlogin" element={<VisitorRoute Component={TutorLogin} />} />
        <Route path="/forgot_tutor_pass" element={<VisitorRoute Component={TutorForgetPass} />} />
        <Route path="/institutionlogin" element={<VisitorRoute Component={InstitutionLogin} />} />
        <Route path="/admin" element={<VisitorRoute Component={AdminLoginPage} />} />
        <Route path="/seejobs" element={<SeeJobs />} />
        <Route path="/hireteachers" element={<HireTeachers />} />
        <Route path="/salesteam" element={<SalesTeam />} />
        <Route path="/register" element={<RegisterOption />} />
      </Routes>
      <Routes>
        <Route path="/register_tutor" element={<TutorRegister />} />
        <Route path="/register/steps/welcome" element={<Step1 />} />
        <Route path="/register/steps/interests" element={<Step2 />} />
        <Route path="/register/steps/about" element={<Step3 />} />
        <Route path="/register/steps/qualifications" element={<Step4 />} />
        <Route path="/register/steps/finalstep" element={<Step5 />} />
      </Routes>
      <Routes>
        <Route path="/admin/dashboard" element={<AdminRoute Component={AdminDashbaord} />} />
        <Route path="/admin/coursemaster" element={<AdminRoute Component={CourseMaster} />} />
        <Route path="/admin/listtutor" element={<AdminRoute Component={ListTutor} />} />
        <Route path="/admin/listtutor/pincodewise" element={<AdminRoute Component={PinCodeWise} />} />
        <Route path="/admin/listinstitution" element={<AdminRoute Component={ListInstitution} />} />
      </Routes>
      <Routes>
        <Route path="/tutor/dashboard" element={<TutorRoute Component={TutorDashbaord} />} />
        <Route path="/tutor/profile" element={<TutorRoute Component={TutorProfile} />} />
        <Route path="/tutor/dashboard/applied_jobs" element={<TutorRoute Component={AppliedJobs} />} />
        <Route path="/tutor/dashboard/see_jobs" element={<TutorRoute Component={SeeJobsPosts} />} />
      </Routes>
      <Routes>
        <Route
          path="/institution/dashboard"
          element={<InstitutionDashbaord />}
        />
        <Route path="/institution/profile" element={<InstitutionRoute Component={InstitutionProfile} />} />
        <Route path="/institution/jobposts" element={<InstitutionPostJobs />} />
        <Route path="/institution/post_new_job" element={<PostNewJob />} />
        <Route path="/institution/jobposts/:id" element={<JobDetails />} />
        <Route path="/institution/jobapplicants" element={<JobApplicantProfile />} />
        <Route path="/institution/expiredjobs" element={<ExpiredJobs />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
