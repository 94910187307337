import React from "react";

const Skills = (props) => {
  const handleClick = (skill) => {
    console.log(`Clicked on skill: ${skill}`);
  };

  return (
      <span
        style={{
          fontWeight:'bold',
          cursor: "pointer",
          padding : "5px 10px 5px 10px",
          margin: "10px 10px 10px 0px",
        }}
        onClick={() => handleClick(props.skill)}
      >
        {props.skill}
    </span>
  );
};

export default Skills;
