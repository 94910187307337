import React, { useEffect, useState } from "react";
import InstitutionNavbar from "../InstitutionNavbar/InstitutionNavbar";
import styles from "./styles.module.css";
import Sidebar from "../Sidebar/Sidebar";
import { host, v } from "../../../config";
import LoadingSpinner from "../../CommonWidget/Loading/LoadingSpinner";

const InstitutionDashbaord = () => {
  const [loading, Loading] = useState(false);
  const [jobsPosted, JobsPosted] = useState({
    lastMonth: 0,
    lastYear: 0,
    allTime: 0,
  });
  const [jobsActive, JobsActive] = useState({
    lastMonth: 0,
    lastYear: 0,
    allTime: 0,
  });
  const [jobsClosed, JobsClosed] = useState({
    lastMonth: 0,
    lastYear: 0,
    allTime: 0,
  });

  useEffect(() => getCount(), []);
  function getCount() {
    Loading(true);
    fetch(`${host}/api/${v}/institution/all_job_count`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        JobsPosted(data[0]);
      })
      .catch((error) => {
        // handleClick({ vertical: "top", horizontal: "right" });
        console.error("Error during fetch:", error);
      })
      .finally(() => {
        // navigate("/register/steps/welcome");
        Loading(false);
      });

    fetch(`${host}/api/${v}/institution/active_job_count`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        JobsActive(data[0]);
      })
      .catch((error) => {
        // handleClick({ vertical: "top", horizontal: "right" });
        console.error("Error during fetch:", error);
      })
      .finally(() => {
        // navigate("/register/steps/welcome");
        Loading(false);
      });

    fetch(`${host}/api/${v}/institution/closed_job_count`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        JobsClosed(data[0]);
      })
      .catch((error) => {
        // handleClick({ vertical: "top", horizontal: "right" });
        console.error("Error during fetch:", error);
      })
      .finally(() => {
        // navigate("/register/steps/welcome");
        Loading(false);
      });
  }
  return (
    <React.Fragment>
      <InstitutionNavbar title="Institution Dashboard" />
      <div className={styles.body}>
        <div style={{ height: "40px" }} />

        <div className={styles.flex}>
          <h1 className={styles.title}>Dashbaord</h1>
          <div className={styles.ca}>
            Current Active :{jobsActive.lastMonth ? jobsActive.lastMonth : "0"}
          </div>
        </div>

        <div style={{ height: "40px" }} />

        <div className={`${styles.card}`}>
          <h2 className={styles.post}>Job Posted</h2>
          <div className={styles.flex}>
            <div className={styles.dashComp}>
              {loading ? (
                <LoadingSpinner />
              ) : (
                <React.Fragment>
                  <h3>
                    Current Month :
                    {jobsPosted.lastMonth === null ? 0 : jobsPosted.lastMonth}
                  </h3>
                </React.Fragment>
              )}
            </div>
            <div className={styles.dashComp}>
              {loading ? (
                <LoadingSpinner />
              ) : (
                <React.Fragment>
                  <h3>
                    {" "}
                    Current Year:
                    {jobsPosted.lastYear === null ? 0 : jobsPosted.lastYear}
                  </h3>
                </React.Fragment>
              )}
            </div>
            <div className={styles.dashComp}>
              {loading ? (
                <LoadingSpinner />
              ) : (
                <React.Fragment>
                  <h3>
                    All Time :
                    {jobsPosted.allTime === null ? 0 : jobsPosted.allTime}
                  </h3>
                </React.Fragment>
              )}
            </div>
          </div>

          <div style={{ height: "20px" }} />

          <h2 className={styles.cls}>Closed Jobs</h2>
          <div className={styles.flex}>
            <div className={`${styles.dashClsd}`}>
              {loading ? (
                <LoadingSpinner />
              ) : (
                <React.Fragment>
                  <h3>
                    Current Month :
                    {jobsClosed.lastMonth === null ? 0 : jobsClosed.lastMonth}
                  </h3>
                </React.Fragment>
              )}
            </div>
            <div className={`${styles.dashComp} ${styles.dashClsd}`}>
              {loading ? (
                <LoadingSpinner />
              ) : (
                <React.Fragment>
                  <h3>
                    {" "}
                    Current Year :
                    {jobsClosed.lastYear === null ? 0 : jobsClosed.lastYear}
                  </h3>
                </React.Fragment>
              )}
            </div>
            <div className={`${styles.dashComp} ${styles.dashClsd}`}>
              {loading ? (
                <LoadingSpinner />
              ) : (
                <React.Fragment>
                  <h3> All Time : {jobsClosed.allTime}</h3>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InstitutionDashbaord;
