import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { host, v } from "../../../config";
import { useLocation, useNavigate } from "react-router-dom";
import SizedBox from "../../Widgets/SizedBox";
import CreatableSelect from "react-select/creatable";
import InstitutionNavbar from "../InstitutionNavbar/InstitutionNavbar";
import LoadingSpinner from "../../CommonWidget/Loading/LoadingSpinner";
import { Box, FormControl, MenuItem, Select } from "@mui/material";

const PostNewJob = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    qualifications: "",
    salary_range: "",
    onl_ofl: "",
    subjects: [],
    pincode: "",
    city: "",
    country: "",
  });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const title = searchParams.get("title") ?? "";
  const description = searchParams.get("description") ?? "";
  const qualifications = searchParams.get("qualifications") ?? "";
  const salary_range = searchParams.get("salary_range") ?? "";
  const onl_ofl = searchParams.get("onl_ofl") ?? "";
  const subjects = searchParams.get("subjects") ?? "";
  const pincode = searchParams.get("pincode") ?? "";
  const city = searchParams.get("city") ?? "";
  const country = searchParams.get("country") ?? "";

  const [courses, Courses] = useState([]);
  const [selectedSubjects, SelectedSubjects] = useState([]);

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  useEffect(() => {
    setFormData({
      title: title,
      description: description,
      qualifications: qualifications,
      salary_range: salary_range,
      onl_ofl: onl_ofl,
      subjects: subjects,
      pincode: pincode,
      city: city,
      country: country,
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubjects = (e) => {
    let subjects = e.map((item) => item.label);
    SelectedSubjects(v);
    setFormData({
      ...formData,
      ["subjects"]: subjects,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  const [loading, Loading] = useState(false);

  function validation() {
    if (formData.title.trim().length === 0) {
      alert("Please Enter Job Title");
      return false;
    }
    if (formData.description.trim().length === 0) {
      alert("Please Enter Job Description");
      return false;
    }
    if (formData.onl_ofl.trim().length === 0) {
      alert("Please Select Job Type");
      return false;
    }

    if (formData.pincode.trim().length === 0) {
      alert("Please Enter Pincode");
      return false;
    }
    if (formData.pincode.trim().length != 6) {
      alert("Pincode length should be 6 digits");
      return false;
    }
    if (formData.city.trim().length === 0) {
      alert("Please Enter City");
      return false;
    }
    if (formData.country.trim().length === 0) {
      alert("Please Enter Country");
      return false;
    }
    return true;
  }

  function addNewJob() {
    if (!validation()) {
      return;
    }
    Loading(true);
    fetch(`${host}/api/${v}/institution/job`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.message === "success") {
          alert("Job Posted Successfully");
          navigate("/institution/jobposts");
        }
      })
      .catch((error) => {
        // handleClick({ vertical: "top", horizontal: "right" });
        console.error("Error during fetch:", error);
      })
      .finally(() => {
        // navigate("/register/steps/welcome");
        Loading(false);
      });
  }

  useEffect(() => {
    fetch(`${host}/api/${v}/institution/course`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        let formattedCourses = data.courses.map((course) => ({
          value: String(course.courseId),
          label: course.name,
        }));
        Courses(formattedCourses);
      })
      .catch((error) => {
        // handleClick({ vertical: "top", horizontal: "right" });
        console.error("Error during fetch:", error);
      })
      .finally(() => {
        // navigate("/register/steps/welcome");
        Loading(false);
      });
  }, []);

  return (
    <React.Fragment>
      <InstitutionNavbar title="Post New Job" />
      <div className={styles.body}>
        <div className={styles.card}>
          <div className={styles.heading}>Post a New Job {title}</div>
          <form onSubmit={handleSubmit} className={styles.formWidth}>
            <div className="form-group">
              <div className={`${styles.flex} ${styles.spaceBetween}`}>
                <div>
                  <label className={styles.label}>Title (*)</label>
                  <input
                    type="text"
                    name="title"
                    className={styles.textArea}
                    value={formData.title}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label className={styles.label}>Description (*)</label>
                  <textArea
                    name="description"
                    className={styles.textArea}
                    value={formData.description}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <SizedBox height="20px" />
              <div className={`${styles.flex} ${styles.spaceBetween}`}>
                <div>
                  <label className={styles.label}>Qualifications</label>
                  <input
                    type="text"
                    name="qualifications"
                    className={styles.textArea}
                    value={formData.qualifications}
                    onChange={handleChange}
                  />
                </div>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <div className={styles.label}>Salary Range</div>
                    <Select
                      name="salary_range"
                      value={formData.salary_range}
                      onChange={handleChange}
                    >
                      <MenuItem value={"Rs 0 to Rs.10,000"}>
                        Rs 0 to Rsv10k
                      </MenuItem>
                      <MenuItem value={"Rs 10,000 to Rs.15,000"}>
                        Rs 10k to Rsv15k
                      </MenuItem>
                      <MenuItem value={"Rs 15,001 to Rs.20,000"}>
                        Rs 15k to Rs 20k
                      </MenuItem>
                      <MenuItem value={"Rs 20,001 to Rs.25,000"}>
                        Rs 20k to Rs 25k
                      </MenuItem>
                      <MenuItem value={"More than Rs. 25,000"}>
                        More than Rs 25,000
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <SizedBox height="20px" />
              <div className={`${styles.flex} ${styles.spaceBetween}`}>
                <div>
                  <Box>
                    <FormControl fullWidth>
                      <div className={styles.label}>Teaching Prefrence</div>
                      <Select
                        name="onl_ofl"
                        value={formData.onl_ofl}
                        onChange={handleChange}
                      >
                        <MenuItem value={"online"}>Online</MenuItem>
                        <MenuItem value={"offline"}>Offline</MenuItem>
                        <MenuItem value={"both"}>Both</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <div>
                  <label className={styles.label}>Subjects (*)</label>
                  <CreatableSelect
                    isMulti
                    options={courses}
                    name="subjects"
                    onChange={handleSubjects}
                  />
                </div>
              </div>

              <SizedBox height="20px" />
              <div className={`${styles.flex} ${styles.spaceBetween}`}>
                <div>
                  <label className={styles.label}>Pin Code (*)</label>
                  <input
                    type="text"
                    name="pincode"
                    className={styles.textArea}
                    value={formData.pincode}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label className={styles.label}>City (*)</label>
                  <input
                    type="text"
                    name="city"
                    className={styles.textArea}
                    value={formData.city}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <SizedBox height="20px" />
              <label className={styles.label}>Country (*)</label>
              <input
                type="text"
                name="country"
                className={styles.textArea}
                value={formData.country}
                onChange={handleChange}
              />
            </div>

            <SizedBox height="30px" />

            {loading ? (
              <LoadingSpinner />
            ) : (
              <button className={styles.btn} onClick={() => addNewJob()}>
                POST JOB
              </button>
            )}
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PostNewJob;
