import React, { useState } from "react";
import Navbar from "../../CommonWidget/Navbar/Navbar";
import { Button, Container, TextField } from "@mui/material";
import SizedBox from "../../Widgets/SizedBox";
import LoadingSpinner from "../../CommonWidget/Loading/LoadingSpinner";
import { host, v } from "../../../config";
import { Link, useNavigate } from "react-router-dom";
import styles from "./styles.module.css";

const TutorLogin = () => {
  const navigate = useNavigate();
  const [loading, Loading] = useState(false);
  const [email, Email] = useState("");
  const [password, Password] = useState("");
  const [errorText, ErrorText] = useState("");

  function handleLogin() {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      alert("Please Enter a Valid Email address");
      return;
    }
    if (password.trim().length === 0) {
      alert("Please Enter a Password");
      return;
    }

    Loading(true);

    fetch(`${host}/api/${v}/visitor/tutorlogin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    })
      .then((response) => {
        if (response.status === 400) {
          ErrorText("Invalid Credentials!");
        }
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.msg === true) {
          navigate("/tutor/dashboard");
          if (data.token) {
            localStorage.setItem("auth", data.token);
          }
        }
      })
      .catch((error) => {
        ErrorText("Invalid Credentials!");
      })
      .finally(() => {
        Loading(false);
      });
  }
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  function handleClick(newState) {
    setState({ ...newState, open: true });
  }
  return (
    <React.Fragment>
      <Navbar />

      <div className={styles.body}>
        <Container className={styles.loginCon} component="main" maxWidth="xs">
          <h2>Log into your Tutor account</h2>

          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(v) => Email(v.target.value)}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="password"
            label="Password"
            name="password"
            value={password}
            onChange={(v) => Password(v.target.value)}
            autoComplete="email"
          />

          {errorText.length > 0 ? (
            <div style={{ color: "red" }}> {errorText} </div>
          ) : (
            <div />
          )}

          <div className={styles.flex}>
            <Link className={styles.btn} to="/register_tutor">
              Register First
            </Link>
            <div>
              <Link className={styles.btn} to="/forgot_tutor_pass">
                Forgotten Password?
              </Link>
            </div>
          </div>
          <br />
          {loading ? (
            <LoadingSpinner />
          ) : (
            <Button onClick={() => handleLogin()} variant="contained">
              Login
            </Button>
          )}
          <SizedBox height="40px" />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TutorLogin;
