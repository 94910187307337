import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import SizedBox from "../../CommonWidget/SizedBox/SizedBox";
import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  Modal,
  Select,
  Typography,
  FormControl,
} from "@mui/material";
import styles from "./styles.module.css";
import { host, v } from "../../../config";


const Jobs = ({
  srNo,
  isSelected,
  imageUrl,
  title,
  description,
  pincode,
  id,
  isActive,
  postingDate,
  onJobClose,
  onCheckBoxChange,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loadingCloseJob, LoadingCloseJob] = useState(false);
  const [succeded, Succeded] = useState(false);
  function openJob() {
    navigate(`${id}`);
  }
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const parsedDatetime = new Date(postingDate);
  const day = parsedDatetime.getDate();
  const month = parsedDatetime.toLocaleString('default', { month: 'short' });
  const year = parsedDatetime.getFullYear();
  const formattedDate = `${day}${month}${year}`;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [reason, Reason] = React.useState("");

  const handleChange = (event) => {
    Reason(event.target.value);
  };

  const handleCloseJob = () => {
    if (reason === "") {
      alert("Please Select a Reason before Closing");
      return;
    }
    fetch(`${host}/api/${v}/institution/close_a_job`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
      body: JSON.stringify({ jobId: id, reason: reason }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        if (response.status === 200) {
          handleClose();
          // Succeded(true);
          alert("Job Closing Successfull !");
          onJobClose(id);
        }
        return response.json();
      })
      .then((data) => {
        // JobsExpired(data.jobs.length);
      })
      .catch((error) => {
        // handleClick({ vertical: "top", horizontal: "right" });
        console.error("Error during fetch:", error);
      })
      .finally(() => {
        // navigate("/register/steps/welcome");
        LoadingCloseJob(false);
      });
  };

  const handleBoxChange = (event) => {
    onCheckBoxChange(id, event.target.checked);
  };
  return (
    <div className={styles.horizontalCard}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <FormControl fullWidth>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Reason to Close of Job
          </Typography>
          <SizedBox height="20px" />
          <Select value={reason} onChange={handleChange}>
            <MenuItem value="Already Hired">Already Hired</MenuItem>
            <MenuItem value="Hire Through Another Source">
              Hire through another Source
            </MenuItem>
            <MenuItem value="No More Required">No More Required</MenuItem>
            <MenuItem value="Duplicate Job">Duplicate Job</MenuItem>
          </Select>
          {succeded ? (
            <div style={{ color: "green" }}>Succefully Close Job!</div>
          ) : null}
          <SizedBox height="20px" />
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={handleCloseJob}>
            Close Job
          </Button>
          <SizedBox height="20px" />
          </FormControl>
        </Box>
      </Modal>
      <div>
        {isSelected === undefined ? null : (
          <Checkbox checked={isSelected} onChange={handleBoxChange} />
        )}

        {srNo + " "}
      </div>
      <img
        src={imageUrl}
        alt=""
        className={styles.cardImage}
        onClick={() => openJob()}
      />
      <div className={styles.cardContent}>
        <h3 className={styles.cardTitle}>{title}</h3>
        <div>
          <FontAwesomeIcon icon={faMapMarkerAlt} />
          Pincode : {pincode}
        </div>
        <div style={styles.description}>{description}</div>
      </div>
      {isActive ? (
        <div>
          <Button
            variant="contained"
            color="error"
            onClick={() => setOpen(true)}
          >
            Close Job
          </Button>
        <div style={styles.description}>{'Posted on : '+formattedDate}</div>
        </div>
      ) : null}
    </div>
  );
};

export default Jobs;
