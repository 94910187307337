import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import AdminNavbar from "../../AdminNavbar/AdminNavbar";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { host, v } from "../../../../config";
import LoadingSpinner from "../../../CommonWidget/Loading/LoadingSpinner";
import Sidebar from "../Sidebar/Sidebar";

const ListTutor = () => {
  const navigate = useNavigate();

  const [loading, Loading] = useState(false);

  const [tutors, Tutors] = useState([]);
  const [filteredTutors, FilteredTutors] = useState([]);
  const [search, Search] = useState("");

  async function Logout() {
    localStorage.clear();
    navigate("/login");
  }

  useEffect(() => {
    fetchTutors();
  }, []);

  function fetchTutors() {
    fetch(`${host}/api/${v}/admin/listtutors`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            alert("Please ReLogin");
          }
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        Tutors(data);
        FilteredTutors(data);
      })
      .catch((error) => {
        // handleClick({ vertical: "top", horizontal: "right" });
        console.error("Error during fetch:", error);
      })
      .finally(() => {
        // navigate("/register/steps/welcome");
        Loading(false);
      });
  }

  function handleSearch(v) {
    Search(v);
    if (v.length === 0) {
      FilteredTutors(tutors);
    }
    let filteredData = tutors.filter((item) =>
      item.pincode.toString().includes(v)
    );
    FilteredTutors(filteredData);
  }

  return (
    <div className={styles.bg}>
      <AdminNavbar />
      <div className={styles.container}>
        <Sidebar />
        <div className={styles.container}></div>
        <div className={styles.card}>
          <span className={styles.heading}>Tutor List</span>
          <span className={styles.heading1Right}>
            count : {filteredTutors.length}
          </span>
          <span className={styles.headingRight}>
            Search
            <input
              value={search}
              onChange={(v) => handleSearch(v.target.value)}
            />
          </span>

          <TableContainer sx={{ maxHeight: 550 }} component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow className={styles.hr}>
                  <TableCell>Name</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">Phone Number</TableCell>
                  <TableCell align="left">Reg date</TableCell>
                  <TableCell align="left">Pincode</TableCell>
                  <TableCell align="left">Action</TableCell>
                </TableRow>
              </TableHead>
              {tutors.length === 0 ? (
                <center>
                  <LoadingSpinner />
                </center>
              ) : (
                <TableBody>
                  {filteredTutors.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">{row.phone1}</TableCell>
                      <TableCell align="left">{row.createdAt}</TableCell>
                      <TableCell align="left">{row.pincode}</TableCell>
                      <TableCell align="left">{row.protein}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default ListTutor;
