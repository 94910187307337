import React from "react";
import Navbar from "../../CommonWidget/Navbar/Navbar";
import { Button, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";

const LoginPage = () => {
  const navigate = useNavigate();

  function handleOptionSelect(option) {
    if (option === 1) {
      navigate("/tutorlogin");
    }
    if (option === 2) {
      navigate("/institutionlogin");
    }
  }

  return (
    <React.Fragment>
      <Navbar />
      <div className={styles.body}>
        <h2>Who are you?</h2>

        <Container
          className={`${styles.reg}  XCon`}
          component="main"
          maxWidth="xs"
        >
          <div className={styles.card}>
            <div>
              {/* <img src="https://www.princetonreview.com/cms-content/HowtoChooseanLSATTutor.jpg" /> */}
              <Button
                variant="outlined"
                className={styles.options}
                onClick={() => handleOptionSelect(1)}
              >
                Tutor
              </Button>
            </div>

            <Button
              variant="outlined"
              className={styles.options}
              onClick={() => handleOptionSelect(2)}
            >
             Employer
            </Button>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default LoginPage;
