import {useNavigate} from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import { useEffect } from 'react';

function AdminRoute(prop){
    const {Component}= prop;
    const navigate = useNavigate();
    useEffect(()=>auth(),[]);
        function auth(){
            const token = localStorage.getItem('auth');
            if (token) {
                try {
                  const decoded = jwtDecode(token);
                  let {adminId} = decoded;
                  if (decoded.exp < Date.now() / 1000) {
                    localStorage.removeItem('auth');
                    navigate('/', { replace: true })
                  }
                  if(!adminId){
                    navigate('/',  { replace: true })
                  }
                } catch (error) {
                  console.error('Error decoding token:', error);
                }
              }else{
                navigate('/', { replace: true })
              }
        }
    return (<Component />)
}

export default AdminRoute;