import React from "react";

const SizedBox = ({ width, height }) => {
  const styles = {
    width: width || "auto",
    height: height || "auto",
  };

  return <div style={styles}></div>;
};

export default SizedBox;
