import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import AdminNavbar from "../../AdminNavbar/AdminNavbar";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { host, v } from "../../../../config";
import LoadingSpinner from "../../../CommonWidget/Loading/LoadingSpinner";
import Sidebar from "../Sidebar/Sidebar";

const ListInstitution = () => {
  const navigate = useNavigate();

  const [loading, Loading] = useState(false);

  const [institutions, Tutors] = useState([]);

  async function Logout() {
    localStorage.clear();
    navigate("/login");
  }

  useEffect(() => {
    fetchTutors();
  }, []);

  function fetchTutors() {
    fetch(`${host}/api/${v}/admin/listinstitutions`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        Tutors(data);
      })
      .catch((error) => {
        // handleClick({ vertical: "top", horizontal: "right" });
        console.error("Error during fetch:", error);
      })
      .finally(() => {
        // navigate("/register/steps/welcome");
        Loading(false);
      });
  }

  return (
    <div className={styles.bg}>
      <AdminNavbar />
      <div className={styles.container}>
        <Sidebar />
        <div className={styles.container}></div>
        <div className={styles.card}>
          <div className={styles.heading}>Institution List</div>
          <TableContainer sx={{ maxHeight: 550 }} component={Paper}>
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow className={styles.hr}>
                  <TableCell>Name</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">Phone Number</TableCell>
                  <TableCell align="left">Reg date</TableCell>
                  <TableCell align="left">Action</TableCell>
                </TableRow>
              </TableHead>
              {institutions.length === 0 ? (
                <center>
                  <LoadingSpinner />
                </center>
              ) : (
                <TableBody className={styles.body}>
                  {institutions.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">{row.phone1}</TableCell>
                      <TableCell align="left">{row.createdAt}</TableCell>
                      <TableCell align="left">{row.protein}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default ListInstitution;
