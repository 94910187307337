import React from "react";
import styles from "./styles.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faMapMarkerAlt, faChair } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import SizedBox from "../../CommonWidget/SizedBox/SizedBox";
import { host } from "../../../config";

const FeaturedJob = ({ value }) => {
  const navigator = useNavigate();
  const imgUrl = `${host}/institution_profile/${value.institutionId}.png`;
  return (
    <>
      <div className={styles.horizontalCard}>
        <img src={imgUrl} alt="" className={styles.cardImage} />

        <div className={styles.cardContent}>
          <div className={styles.cardHeader}>Featured Job</div>
          <div className={styles.cardTitle}>{value.name}</div>
          <div className={styles.subCard}>
            <div>
              <FontAwesomeIcon icon={faBuilding} className={styles.icon} />
            {value.city+', '+value.country}
            </div>
            <SizedBox width="24px" />
            <div>
              <FontAwesomeIcon icon={faMapMarkerAlt} className={styles.icon} />
              Pincode : {value.pincode}
            </div>
            <SizedBox width="24px" />
            <div>
              <FontAwesomeIcon icon={faChair} className={styles.icon} />
              Type : {'Offline'}
            </div>
          </div>
          <div className={styles.description}>{value.description}</div>
        </div>

        <button
          className={styles.cardButton}
          onClick={() => navigator("/tutorlogin")}
        >
          Quick Apply
        </button>
      </div>
      {/* <hr/> */}
    </>
  );
};

export default FeaturedJob;
