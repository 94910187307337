import React from "react";

const SizedBox = (props) => {
  return (
    <div
      style={{ height: props.height || "auto", width: props.width || "auto" }}
    />
  );
};

export default SizedBox;
