import React from "react";
import { Link } from "react-router-dom";
import style from "./styles.module.css";

const Sidebar = () => {
  return (
    <></>
    // <div className={style.sidebar}>
    //   <ul>
    //     <li>
    //       <Link to="/institution/dashboard"> Dashboard</Link>
    //     </li>
    //     <li>
    //       <Link to="/institution/jobposts">Jobs</Link>
    //     </li>
    //     <li>
    //       <Link to="/institution/expiredjobs">Expired Jobs</Link>
    //     </li>
    //   </ul>
    // </div>
  );
};

export default Sidebar;
