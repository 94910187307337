import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import SizedBox from "../../CommonWidget/SizedBox/SizedBox";
import {
  Modal,
  Box,
  Button,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import styles from "./styles.module.css";
import { host, v } from "../../../config";
import PostNewJob from "../PostNewJob/PostNewJob";

const ExpiredJob = ({
  srNo,
  imageUrl,
  title,
  description,
  pincode,
  id,
  city,
  state,
  closedReason,
  closedDate,
  onJobClose,
}) => {
  const navigate = useNavigate();
  const [showModal, ShowModal] = useState(false);
  const [loadingCloseJob, LoadingCloseJob] = useState(false);
  const [succeded, Succeded] = useState(false);
  function openJob() {
    navigate(`${id}`);
  }
  const handleOpen = () => {
    ShowModal(true);
  };
  const handleClose = () => {
    ShowModal(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  console.log(closedDate);
  let year, month, day;
  if (closedDate) {
    year = closedDate.slice(0, 4);
    month = closedDate.slice(4, 6);
    day = closedDate.slice(6, 8);
  }
  const months = [ "Jan", "Feb",  "Mar",  "Apr", "May", "Jun", "Jul",  "Aug", "Sep", "Oct", "Nov", "Dec" ];
  const abbreviatedMonth = months[parseInt(month, 10) - 1];
  const formattedDate = `${day}${abbreviatedMonth}${year}`;

  const [closeReason, Reason] = React.useState("");

  const handleRePost = () => {
    navigate(
      `/institution/post_new_job?title=${title}&description=${description}&qualifications=Any&pincode=${pincode}&city=${city}&state=${state}&country=India`
    );
  };

  const handleCloseJob = () => {
    if (closeReason === "") {
      alert("Please Select a Reason before Closing");
      return;
    }
    fetch(`${host}/api/${v}/institution/close_a_job`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
      body: JSON.stringify({ jobId: id, closeReason: closeReason }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        if (response.status === 200) {
          handleClose();
          // Succeded(true);
          alert("Job Closing Successfull !");
          onJobClose(id);
        }
        return response.json();
      })
      .then((data) => {
        // JobsExpired(data.jobs.length);
      })
      .catch((error) => {
        // handleClick({ vertical: "top", horizontal: "right" });
        console.error("Error during fetch:", error);
      })
      .finally(() => {
        // navigate("/register/steps/welcome");
        LoadingCloseJob(false);
      });
  };

  return (
    <div className={styles.horizontalCard}>
      {/* <Modal
        open={showModal}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
         <PostNewJob />
          <SizedBox height="20px" />
        </Box>
      </Modal> */}
      <div style={{ marginRight: "10px" }}>{srNo}</div>
      <img
        src={imageUrl}
        alt=""
        className={styles.cardImage}
      // onClick={() => openJob()}
      />
      <div className={styles.cardContent}>
        <h3 className={styles.cardTitle}>{title}</h3>
        <div>
          <FontAwesomeIcon icon={faMapMarkerAlt} />
          Pincode : {pincode}
        </div>
        <div>
          <FontAwesomeIcon icon={faMapMarkerAlt} />
          Location : {city + " , " + state}
        </div>
        <div style={styles.description}>{description}</div>
      </div>
      <div>
        <Button
          variant="contained"
          onClick={() => {
            handleRePost();
            ShowModal(true);
          }}
        >
          Re-Post this Job
        </Button>
        <div>{"Closed on : " + formattedDate}</div>
        <div>{"Reason : " + closedReason}</div>
      </div>
    </div>
  );
};

export default ExpiredJob;
