import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Navbar from "../../CommonWidget/Navbar/Navbar";
import Footer from "../../CommonWidget/Footer/Footer";
import SizedBox from "../../CommonWidget/SizedBox/SizedBox";
import { Button, MenuItem, Select } from "@mui/material";
import { Link } from "react-router-dom";
import { host, v } from "../../../config";
import LoadingSpinner from "../../CommonWidget/Loading/LoadingSpinner";
import "./LandingPage.css";
import styles from "./LandingPage.module.css";

const LandingPage = () => {
  const [loading, Loading] = useState(false);
  const [allJobs, AllJobs] = useState([]);
  const [searchTitle, SearchTitle] = useState("");
  const [searchLocation, SearchLocation] = useState("");
  const [jobPref, JobPref] = useState("all");
  const [featuredJobs, FeaturedJobs] = useState([]);
  const [cities, Cities] = useState([]);
  const [pincodes, Pincodes] = useState([]);
  const [countries, Countries] = useState([]);
  const [filteredFeaturedJobs, FilteredFeaturedJobs] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(true);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  function getJobs() {
    Loading(true);
    fetch(`${host}/api/${v}/visitor/job`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        AllJobs(data.jobs);
        FeaturedJobs(data.jobs);
        FilteredFeaturedJobs(data.jobs);
        let country = [...new Set(data.jobs.map((item) => item.country))];
        Countries(country);
        let city = [...new Set(data.jobs.map((item) => item.city))];
        Cities(city);
        let pincode = [...new Set(data.jobs.map((item) => item.pincode))];
        Pincodes(pincode);
      })
      .catch((error) => {
        // handleClick({ vertical: "top", horizontal: "right" });
        console.error("Error during fetch:", error);
      })
      .finally(() => {
        // navigate("/register/steps/welcome");
        Loading(false);
      });
  }

  // function handlePincodeCheck(c, v) {
  //   // console.log(v);
  //   // console.log(c);
  //   if (c === false) {
  //     let newPincodes = selectedPincodes.filter((item) => item !== v);
  //     SelectedPincodes(newPincodes);
  //   }
  //   if (c === true && !selectedPincodes.includes(v)) {
  //     SelectedPincodes([...selectedPincodes, v]);
  //   }
  // }

  function handleSearchTitle(v) {
    let jobNameMatches = allJobs.filter((job) =>
      job.jobName.toLowerCase().includes(v)
    );

    FeaturedJobs(jobNameMatches);
    FilteredFeaturedJobs(jobNameMatches);
  }

  function handleJobPrefChange(v) {
    JobPref(v);
    if (v === "all") {
      FeaturedJobs(allJobs);
      FilteredFeaturedJobs(allJobs);
      return;
    }
    let jobMatches = allJobs.filter(
      (job) => job.onl_ofl.toLowerCase() === v.toLowerCase()
    );

    FeaturedJobs(jobMatches);
    FilteredFeaturedJobs(jobMatches);
  }

  function handleSearchLocation(v) {
    let countryMatches = featuredJobs.filter((obj) =>
      obj.country.toString().toLowerCase().includes(v)
    );

    let cityMatches = featuredJobs.filter((obj) =>
      obj.city.toString().toLowerCase().includes(v)
    );
    let pincodeMatches = featuredJobs.filter((obj) =>
      obj.pincode.toString().includes(v)
    );
    const combinedArray = [
      ...cityMatches,
      ...countryMatches,
      ...pincodeMatches,
    ];
    const distinctArray = [...new Set(combinedArray)];
    FilteredFeaturedJobs(distinctArray);
  }

  const jobs = [
    {
      id: 1,
      company: "Linear Company",
      companyLogo:
        "https://logodownload.org/wp-content/uploads/2015/04/whatsapp-logo-7.png",
      title: "Drawing Teacher",
      location: "Mumbai",
      type: "Full time",
      salary: "$10-77k",
      posted: "29 min ago",
      description:
        "lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },

    {
      id: 2,
      company: "Acnisdi Company",
      companyLogo:
        "https://www.freepnglogos.com/uploads/company-logo-png/company-logo-telestream-press-kit-12.png",
      title: "Software Trainer",
      location: "Chennai",
      type: "Full time",
      salary: "$30-55k",
      posted: "2 days ago",
      description:
        "lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },

    {
      id: 3,
      company: "Acnisdi Company",
      companyLogo:
        "https://i.pinimg.com/originals/69/e0/64/69e0645c1a01e38f6092dac2703e760d.png",
      title: "Front-end Developer",
      location: "Kerala",
      type: "Full time",
      salary: "$50-55k",
      posted: "1 week ago",
      description:
        "lorecd lojndvd sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },

    {
      id: 4,
      company: "Techz Company",
      companyLogo:
        "https://www.freepnglogos.com/uploads/company-logo-png/bmw-car-company-logo-png-transparent-image-3.png",
      title: "Back-end Developer",
      location: "Bangalore",
      type: "Full time",
      salary: "$10-45k",
      posted: "3 week ago",
      description:
        "lorecd lojndvd sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
  ];

  useEffect(() => getJobs(), []);
  return (
    <React.Fragment>
      <div className="main">
        <Navbar />
        <div className={styles.header}>
          <div className="above-hero">
            <div className={styles.hero}>
              <div>
                <h1 className={styles.t1}>Find Teaching Jobs in India!</h1>
                <img
                  src="https://i.ibb.co/MRZCwrw/Group.png"
                  alt="icon"
                  className={styles.bi}
                />
                <p>
                  Search hundreads of the teaching Jobs ahead, including
                  opportunities with international schools, government programs,
                  universities and language colleges in INDIA.
                </p>
              </div>
              <img
                src="/landing_banner.png"
                alt="icon"
              />
            </div>
          </div>

          <div className={styles.body}>
            <div className={styles.section1}>
              <h1>What is TutorPlatter?</h1>
              <p>
                TutorPlatter is dedicated to bridging the gap between students
                and top notch tutors worldwide. Whether you're seeking expert
                help or looking to share your knowledge, our platform offers a
                seamless experience for everyone.
              </p>

              <div className={styles.introBoxs}>
                <div className="intro-box">
                  <img src="https://i.ibb.co/fvJZ1Gg/Job.png" alt="icon" />
                  <h4>For Tutors</h4>
                  <p>
                    Become part of TutorPlatter’s vibrant and supportive network
                    of educators. Joining our community means more than just
                    offering your teaching services—it’s about engaging with a
                    global network of passionate tutors and benefiting from a
                    range of resources and support designed to help you succeed.
                  </p>
                </div>

                <div className="intro-box">
                  <img src="https://i.ibb.co/WkvRW2W/Deal.png" alt="icon" />
                  <h4>For Educational Institutes</h4>
                  <p>
                    Partner with TutorPlatter to enhance your institute’s
                    educational offerings and attract top-quality tutors. Our
                    partnership opportunities provide a platform for your
                    institute to gain visibility, connect with skilled
                    educators, and showcase your programs to a broader audience.
                  </p>
                </div>

                <div className="intro-box">
                  <img src="https://i.ibb.co/60NF6KQ/Student.png" alt="icon" />
                  <h4>For Students</h4>
                  <p>
                    Discover the ideal tutor who meets your specific academic
                    needs with ease. TutorPlatter’s advanced search and matching
                    system helps you find experienced and qualified tutors who
                    are perfectly suited to help you achieve your educational
                    goals.
                  </p>
                </div>
              </div>

              {/*  */}
            </div>

            {/* section 2 */}
            <div className="section2">
              <div className="blue-box">
                <img src="https://i.ibb.co/BwpK5GM/Desktop-1.png" alt="icon" />

                <div className="blue-box-content">
                  <h1 className={styles.t1}>Find Your Ideal Tutor</h1>
                  <p>Get Your Tutor Today!</p>
                  <button className="btns">Sign Up For Free</button>
                </div>
              </div>
            </div>

            {/* section 3 */}
            <div className="section3 container">
              <h1>Do You Want a Teaching Career?</h1>
              <p className="title-des">
                Take your teaching skills overseas. Improve your resume.
                Whatever your path, all of our programs are developed with one
                thing in mind: the power of a great teacher.
              </p>

              <div className="job-listing">
                {jobs.map((job) => (
                  <div key={job.id} className="job-card">
                    <div className="job-content">
                      <img
                        src={job.companyLogo}
                        alt={job.company}
                        className="company-logo"
                      />
                      <div className="job-details">
                        <div className="job-header">
                          <p className="company-name">{job.company}</p>
                          <h2>{job.title}</h2>
                        </div>
                        <div className="job-meta">
                          <p className="job-detail">
                            <i className="bi bi-briefcase"></i> {job.type}
                          </p>
                          <p className="job-detail">
                            <i className="bi bi-currency-dollar"></i>{" "}
                            {job.salary}
                          </p>
                          <p className="job-detail">
                            <i className="bi bi-geo-alt"></i> {job.location}
                          </p>
                          <p className="job-detail">
                            <i className="bi bi-clock"></i> {job.posted}
                          </p>
                        </div>
                        <p className="job-description">{job.description}</p>
                      </div>
                      <button className="bookmark-btn">
                        <i className="bi bi-bookmark"></i>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* section 4 */}
            <div className="section2 container mb-5">
              <div className="blue-box">
                <div className="blue-box-content">
                  <h1>Join Our Network of Expert Tutors</h1>
                  <p>Create Your Tutor Profile Today!</p>
                  <button className="btns">Sign Up For Free</button>
                </div>
                <img src="https://i.ibb.co/Yd3KPhP/Desktop.png" alt="icon" />
              </div>
            </div>

            {/* footer */}
            <div className="footer">
              <div>
                <img
                  src="https://usercentereddesign.co.in/tutor/images/logo.png"
                  title="Tutor Platter"
                  alt="Tutor Platter"
                  className="logo"
                />
                <p>
                  Great platform for the job seeker that passionate about
                  startups. Find your dream job easier.
                </p>
              </div>

              <div className="footer-links">
                <h4>About</h4>
                <div>
                  <a href="#">About Us</a>
                  <a href="#">Terms</a>
                  <a href="#">Privacy Policy</a>
                </div>
              </div>

              <div className="footer-notify">
                <h5>Get job notifications</h5>
                <p>The latest job news, articles, sent to your inbox weekly.</p>
                <div className="footer-input">
                  <input type="email" placeholder="Enter your email" />
                  <button>Subscribe</button>
                </div>
              </div>
            </div>

            <div className="footer-bottom">
              <p>&copy; 2024 TUTOR PLATTER. All rights reserved.</p>
              <div>
                <a href="#">
                  <i className="bi bi-facebook"></i>
                </a>
                <a href="#">
                  <i className="bi bi-instagram"></i>
                </a>
                <a href="#">
                  <i className="bi bi-whatsapp"></i>
                </a>
                <a href="#">
                  <i className="bi bi-linkedin"></i>
                </a>
                <a href="#">
                  <i className="bi bi-x"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LandingPage;
