import React, { useState } from "react";
import Navbar from "../../../CommonWidget/Navbar/Navbar";
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import SizedBox from "../../../Widgets/SizedBox";
import LoadingSpinner from "../../../CommonWidget/Loading/LoadingSpinner";
import { host, v } from "../../../../config";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";

const Step3 = () => {
  const navigate = useNavigate();
  const [loading, Loading] = useState(false);
  const [name, Name] = useState("");
  const [email, Email] = useState("");
  const [password, Password] = useState("");

  function handleStep3() {
    navigate("/register/steps/qualifications");
    // Loading(true);

    // fetch(`${host}/api/${v}/register`, {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({
    //     name: name,
    //     email: email,
    //     password: password,
    //   }),
    // })
    //   .then((response) => {
    //     if (response.status === 400) {
    //       console.log(45);
    //       // handleClick({ vertical: "top", horizontal: "right" });
    //     }
    //     if (!response.ok) {
    //       throw new Error(`HTTP error! Status: ${response.status}`);
    //     }
    //     return response.json();
    //   })
    //   .then((data) => {
    //     if (data.status === true) {
    //       navigate("/register/steps/welcome");
    //     }
    //   })
    //   .catch((error) => {
    //     // handleClick({ vertical: "top", horizontal: "right" });
    //     console.error("Error during fetch:", error);
    //   })
    //   .finally(() => {
    //     Loading(false);
    //   });
  }

  return (
    <React.Fragment>
      <Navbar />
      <div className={styles.body}>
        <h1>Tell us more about yourself</h1>
        Speed up the visa and hiring process by adding your info.
        <SizedBox height="10px" />
        <Container
          className={`${styles.reg} XCon`}
          component="main"
          maxWidth="xs"
        >
          <SizedBox height="20px" />
          <FormControl fullWidth>
            <div className={styles.left}>
              Allow hiring schools to contact you directly?*
            </div>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={name}
              // onChange={handleChange}
            >
              <MenuItem value={10}>Yes</MenuItem>
              <MenuItem value={20}>No</MenuItem>
            </Select>
            <SizedBox height="20px" />
            <div className={styles.left}>Fluent languages:*</div>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={name}
              // onChange={handleChange}
            >
              <MenuItem value={10}>English</MenuItem>
              <MenuItem value={20}>Hindi</MenuItem>
            </Select>
            <SizedBox height="20px" />
            <div className={styles.left}>Date of birth:*</div>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={name}
              // onChange={handleChange}
            >
              <MenuItem value={10}>01</MenuItem>
              <MenuItem value={20}>02</MenuItem>
              <MenuItem value={20}>03</MenuItem>
              <MenuItem value={20}>04</MenuItem>
              <MenuItem value={20}>05</MenuItem>
              <MenuItem value={20}>06</MenuItem>
              <MenuItem value={20}>07</MenuItem>
              <MenuItem value={20}>08</MenuItem>
              <MenuItem value={20}>09</MenuItem>
              <MenuItem value={20}>10</MenuItem>
              <MenuItem value={20}>11</MenuItem>
              <MenuItem value={20}>12</MenuItem>
            </Select>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={name}
              // onChange={handleChange}
            >
              <MenuItem value={10}>01</MenuItem>
              <MenuItem value={20}>02</MenuItem>
              <MenuItem value={20}>03</MenuItem>
              <MenuItem value={20}>04</MenuItem>
              <MenuItem value={20}>05</MenuItem>
              <MenuItem value={20}>06</MenuItem>
              <MenuItem value={20}>07</MenuItem>
              <MenuItem value={20}>08</MenuItem>
              <MenuItem value={20}>09</MenuItem>
              <MenuItem value={20}>10</MenuItem>
              <MenuItem value={20}>11</MenuItem>
              <MenuItem value={20}>12</MenuItem>
            </Select>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={name}
              // onChange={handleChange}
            >
              <MenuItem value={10}>01</MenuItem>
              <MenuItem value={20}>02</MenuItem>
              <MenuItem value={20}>03</MenuItem>
              <MenuItem value={20}>04</MenuItem>
              <MenuItem value={20}>05</MenuItem>
              <MenuItem value={20}>06</MenuItem>
              <MenuItem value={20}>07</MenuItem>
              <MenuItem value={20}>08</MenuItem>
              <MenuItem value={20}>09</MenuItem>
              <MenuItem value={20}>10</MenuItem>
              <MenuItem value={20}>11</MenuItem>
              <MenuItem value={20}>12</MenuItem>
            </Select>
            <SizedBox height="20px" />
            <div className={styles.left}>Gender : *</div>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={name}
              // onChange={handleChange}
            >
              <MenuItem value={10}>Male</MenuItem>
              <MenuItem value={20}>Female</MenuItem>
              <MenuItem value={20}>Others</MenuItem>
            </Select>
            <div className={styles.left}>Phone Number : *</div>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              value={name}
              onChange={(v) => Name(v.target.value)}
            />
            <div className={styles.left}>Upload your Resume or CV</div>
            <div className={styles.left}>
              <Checkbox />I consent to Teach Away receiving this document,
              storing this information and securely sharing with schools through
              the Teach Away platform.
            </div>
          </FormControl>

          <SizedBox height="20px" />
          {loading ? (
            <LoadingSpinner />
          ) : (
            <Button onClick={() => handleStep3()} variant="contained">
              Next : Qualifcations
            </Button>
          )}
          <SizedBox height="30px" />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Step3;
