import {useNavigate} from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import { useEffect } from 'react';

function InstitutionRoute(prop){
    const {Component}= prop;
    const navigate = useNavigate();
        function auth(){
            const token = localStorage.getItem('auth');
            if (token) {
                try {
                  const decoded = jwtDecode(token);
                  let {institutionId} = decoded;
                  if (decoded.exp < Date.now() / 1000) {
                    localStorage.removeItem('auth');
                    navigate('/')
                  }
                  if(!institutionId){
                    navigate('/')
                  }
                } catch (error) {
                  console.error('Error decoding token:', error);
                }
              }else{
                navigate('/')
              }
        }
        useEffect(()=>auth(),[]);
    return (<Component />)
}

export default InstitutionRoute;