import React, { useEffect, useState } from "react";
import TutorNavbar from "../TutorNavbar/TutorNavbar";
import styles from "./styles.module.css";
import FeaturedJob from "../FeaturedJobs/FeaturedJob";
import LoadingSpinner from "../../CommonWidget/Loading/LoadingSpinner";
import { host, v } from "../../../config";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";

const SeeJobsPosts = () => {
  const [loading, Loading] = useState(false);
  const [allJobs, AllJobs] = useState([]);

  const [searchTitle, SearchTitle] = useState("");
  const [searchLocation, SearchLocation] = useState("");

  const [on_of, setOn_Of] = useState("All Types");
  const [salaryRange, setSalaryRange] = useState("All Ranges");
  const [postTime, setPostTime] = useState("All Time");

  const [filteredJobs, FilteredJobs] = useState([]);

  function filterJobs() {
    const lowerCaseSearchLocation = searchLocation.trim().toLowerCase();
    const lowerCaseSearchTitle = searchTitle.trim().toLowerCase();

    // Filter by job name
    let jobNameMatches =
      lowerCaseSearchTitle === ""
        ? allJobs
        : allJobs.filter((job) =>
            job.jobName.toLowerCase().includes(lowerCaseSearchTitle)
          );

    // Filter by location (country, city, or pincode)
    let locationMatches = allJobs.filter((job) => {
      const matchesCountry = job.country
        .toString()
        .toLowerCase()
        .includes(lowerCaseSearchLocation);
      const matchesCity = job.city
        .toString()
        .toLowerCase()
        .includes(lowerCaseSearchLocation);
      const matchesPincode = job.pincode.toString().includes(searchLocation);
      return (
        lowerCaseSearchLocation === "" ||
        matchesCountry ||
        matchesCity ||
        matchesPincode
      );
    });

    let jobTypeMatches =
      on_of === "All Types"
        ? allJobs
        : allJobs.filter(
            (job) => job.onl_ofl.toLowerCase() === on_of.toLowerCase()
          );

    let salaryRangeMatches =
      salaryRange === "All Ranges"
        ? allJobs
        : allJobs.filter(
            (job) => job.salary_range === getSalRange(salaryRange)
          );

    const now = new Date();

    let filteredTime = allJobs.filter((job) => {
      const postingDate = new Date(job.postingDate);
      switch (postTime) {
        case "All Time":
          return true;
        case "Past 24 hours":
          return now - postingDate <= 24 * 60 * 60 * 1000;
        case "Last Month":
          return now - postingDate <= 30 * 24 * 60 * 60 * 1000;
        default:
          return false;
      }
    });

    let filteredJobs = jobNameMatches.filter(
      (job) =>
        locationMatches.includes(job) &&
        jobTypeMatches.includes(job) &&
        salaryRangeMatches.includes(job) &&
        filteredTime.includes(job)
    );

    FilteredJobs(filteredJobs);
  }

  useEffect(() => {
    filterJobs();
  }, [searchTitle, searchLocation, on_of, salaryRange, postTime]);

  function getSalRange(range) {
    switch (range) {
      case "Rs 0 to 10k":
        return "Rs 0 to Rs 10,000";
      case "Rs 10k to 15k":
        return "Rs 10,001 to Rs 15,000";
      case "Rs 15k to 20k":
        return "Rs 15,001 to Rs 20,000";
      case "Rs 20k to 25k":
        return "Rs 20,001 to Rs 25,000";
      case "More than Rs 25k":
        return "More than Rs 25,000";
      default:
        return null; // or any default value you'd like to return
    }
  }

  function getJobs() {
    Loading(true);
    fetch(`${host}/api/${v}/tutor/job`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        AllJobs(data.jobs);
        FilteredJobs(data.jobs);
      })
      .catch((error) => {
        console.error("Error during fetch:", error);
      })
      .finally(() => {
        // navigate("/register/steps/welcome");
        Loading(false);
      });
  }

  useEffect(() => getJobs(), []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 2,
  };

  return (
    <React.Fragment>
      <TutorNavbar title="Find Jobs" />

      <div className={styles.body}>
        <div className={styles.flex_hed}>
          <div className={styles.hed}>
            Find your <span className={styles.c_g}>new job</span> today
          </div>
          <div className={styles.des}>
            Search hundreads of Teachers Jobs ahead, including opportunities
            with international schools, government , univerties programs and
            laguage colleges in INDIA
          </div>

          <div className={styles.flx_srh}>
            <div className={styles.searchBox}>
              <FontAwesomeIcon icon={faMagnifyingGlass} />
              <input
                type="text"
                placeholder="What position you are looking for?"
                value={searchTitle}
                onChange={(v) => {
                  SearchTitle(v.target.value.toLowerCase());
                }}
                className={styles.searchInput}
              />
            </div>

            <div className={styles.searchBox}>
              <FontAwesomeIcon icon={faLocationDot} />
              <input
                type="text"
                placeholder="City or State or Country or Pincode"
                value={searchLocation}
                onChange={(v) => {
                  SearchLocation(v.target.value.toLowerCase());
                }}
                className={styles.searchInput}
              />
            </div>

            <div className={styles.searchButton}>Search Jobs</div>
          </div>
        </div>

        <div className={styles.flex_body}>
          <div className={styles.filterCard}>
            <h3> Filters</h3>
            <ul className={styles.list}>
              <div className={styles.f_label}>Job Type</div>
              {["All Types", "Online", "Offline"].map((v) => (
                <li className={styles.listItem}>
                  <input
                    type="radio"
                    name={v}
                    value={v}
                    checked={on_of === v}
                    onChange={() => setOn_Of(v)}
                    className={`${styles.radioButton} ${styles.radioButtonChecked}`}
                  />
                  <label htmlFor="jjk" className={styles.label}>
                    {v}
                  </label>
                </li>
              ))}
            </ul>
            {/* <ul className={styles.list}>
              <div className={styles.f_label}>Tutor Type</div>
              {["Any", "Home Tutor"].map((v) => (
                <li className={styles.listItem}>
                  <input
                    type="checkbox"
                    name="radio-group"
                    value="true"
                    checked={true}
                  />
                  <label htmlFor="jjk" className={styles.label}>
                    {v}
                  </label>
                </li>
              ))}
            </ul> */}
            <ul className={styles.list}>
              <div className={styles.f_label}>Salary Range</div>
              {[
                "All Ranges",
                "Rs 0 to 10k",
                "Rs 10k to 15k",
                "Rs 15k to 20k",
                "Rs 20 to 25k",
                "More than Rs 25k",
              ].map((v) => (
                <li className={styles.listItem}>
                  <input
                    type="radio"
                    name={v}
                    value={v}
                    checked={salaryRange === v}
                    onChange={() => setSalaryRange(v)}
                    className={`${styles.radioButton} ${styles.radioButtonChecked}`}
                  />
                  <label htmlFor="jjk" className={styles.label}>
                    {v}
                  </label>
                </li>
              ))}
            </ul>
            <ul className={styles.list}>
              <div className={styles.f_label}>Date of Posting</div>
              {["All Time", "Past 24 hours", "Last Month"].map((v) => (
                <li className={styles.listItem}>
                  <input
                    type="radio"
                    name={v}
                    value={v}
                    checked={v === postTime}
                    onChange={() => setPostTime(v)}
                    className={`${styles.radioButton} ${styles.radioButtonChecked}`}
                  />
                  <label htmlFor="jjk" className={styles.label}>
                    {v}
                  </label>
                </li>
              ))}
            </ul>
          </div>

          <div className={styles.jobCards}>
            <div className={styles.blueBg} />
            <div className={`${styles.jobCard}`}>
              <div>
                <div className={styles.count}>{filteredJobs.length} Jobs</div>
              </div>

              <div />

              {loading ? (
                <LoadingSpinner />
              ) : filteredJobs.length === 0 ? (
                <div className={styles.noJob}> No Job Found ! </div>
              ) : (
                filteredJobs.map((value, i) => {
                  return (
                    <FeaturedJob
                      imageUrl={
                        "https://www.teachaway.com/sites/default/files/styles/header_image_1024x575/public/job-board-main_0.png?itok=P_hWWo-G"
                      }
                      key={i}
                      details={value}
                      appiled={value.isJobApplied}
                    />
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SeeJobsPosts;
