import React from "react";
import styles from "./styles.module.css";

const Experience = ({ experience }) => {
  // const handleClick = (skill) => {
  //   console.log(`Clicked on skill: ${skill}`);
  // };

  return (
    <div
      className={styles.card}
      // onClick={() => handleClick(props.skill)}
    >
      <div className={styles.apart}>
        <div className={styles.title}>{experience.name}</div>
        <div className={styles.date}> {experience.fromDate} to {experience.toDate} </div>
      </div>
      <div className={styles.description}>{experience.description}</div>
    </div>
  );
};

export default Experience;
