import React, { useState } from "react";
import Navbar from "../../CommonWidget/Navbar/Navbar";
import "./HireTeachers.css";
import FeaturedJob from "./FeaturedJob";
import Footer from "../../CommonWidget/Footer/Footer";

const HireTeachers = () => {
  const [job, Job] = useState("");
  const [loading, Loading] = useState(false);
  const [featuredJobs, FeaturedJobs] = useState([]);
  const [filteredJobs, FilteredJobs] = useState([]);
  const [names, Names] = useState([]);

  const handleJobChange = (value) => {
    Job(value.target.value);
    let newFeaturedJobs = featuredJobs.filter(
      (job) => job.name.toLowerCase() === value.target.value.toLowerCase()
    );
    FilteredJobs(newFeaturedJobs);
  };
  return (
    <React.Fragment>
      <Navbar />
      <div className="hero">
        <img
         src="./bg.png"
          alt=""
          style={{ height: "250px", width: "100%" }}
        />
        <div className="text-overlay">
          <h1 style={{color:"white"}}>Find Teaching Jobs in India</h1>
          <p className="hdtext">
            Search hundreds of teaching jobs abroad, including opportunities
            with international schools, government programs, universities <br />
            and language colleges in INDIA.
          </p>
        </div>
      </div>
      <div className="sec1">
        <h1>Recruiting Teachers made Easy</h1>
        <FeaturedJob
          imageUrl={
            "https://www.teachaway.com/sites/default/files/styles/header_image_1024x575/public/job-board-main_0.png?itok=P_hWWo-G"
          }
          title={"Art Teacher"}
          member1={"Ludhiyana, U.P."}
          member2={"PinCode : 254565"}
          member3={"Only For Online Teaching"}
        />
        <FeaturedJob
          imageUrl={
            "https://www.teachaway.com/sites/default/files/styles/header_image_1024x575/public/job-board-main_0.png?itok=P_hWWo-G"
          }
          title={"Drawing Teacher"}
          member1={"Agra, U.P."}
          member2={"PinCode : 657845"}
          member3={"Need 5 students"}
        />
        <FeaturedJob
          imageUrl={
            "https://www.teachaway.com/sites/default/files/styles/header_image_1024x575/public/job-board-main_0.png?itok=P_hWWo-G"
          }
          title={"Music Teacher"}
          member1={"Lucknow, U.P."}
          member2={"PinCode : 987845"}
          member3={"I have 3 years of Experience"}
        />
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default HireTeachers;
