import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import style from "./style.module.css";
import { services } from "../../Uiities/utils";

const InstitutionNavbar = () => {
  const navigate = useNavigate();
  const [insName, InsName] = useState();

  function getName() {
    try {
      const token = localStorage.getItem("auth");
      const name = jwtDecode(token).name;
      if (name) {
        InsName(name);
      }
    } catch {}
  }

  useEffect(() => getName(), []);

  async function Logout() {
    localStorage.clear();
    navigate("/");
  }
  return (
    <div className={style.nav}>
      <hed>

      
        <div className={style.title}>
          <img style={{ width: "55px" }} src="/logo.png" />
        </div>

        <div className={style.nav_links}>
          <Link className={style.link} to="/institution/dashboard">
            <Button variant="text">Dashboard</Button>
          </Link>
          <Link className={style.link} to="/institution/jobposts">
            <Button variant="text">Active Jobs</Button>
          </Link>
          <Link className={style.link} to="/institution/expiredjobs">
            <Button variant="text">Closed Jobs</Button>
          </Link>
          <Link className={style.link} to="/institution/post_new_job">
            <Button variant="text">Post NewJob</Button>
          </Link>
        </div>

        <div>
          <Link className={style.insName} to="/institution/profile">
            {services.truncateString(insName)}
          </Link>

          <Button
            variant="contained"
            color="error"
            aria-label="outlined primary button group"
            className={style.rightDiv}
            onClick={() => Logout()}
          >
            Logout
          </Button>
        </div>
        </hed>

      <div className={style.strip} />
    </div>
  );
};

export default InstitutionNavbar;
