import React, { useEffect, useState } from "react";
import Navbar from "../../CommonWidget/Navbar/Navbar";
import "./SeeJobs.css";
import FeaturedJob from "./FeaturedJob";
import Footer from "../../CommonWidget/Footer/Footer";
import SizedBox from "../../CommonWidget/SizedBox/SizedBox";
import { InputLabel, MenuItem, Select } from "@mui/material";
import LoadingSpinner from "../../CommonWidget/Loading/LoadingSpinner";
import { host, v } from "../../../config";

const SeeJobs = () => {
  const [job, Job] = useState("All");
  const [loading, Loading] = useState(false);
  const [featuredJobs, FeaturedJobs] = useState([]);
  const [filteredJobs, FilteredJobs] = useState([]);
  const [names, Names] = useState([]);

  const handleJobChange = (value) => {
    Job(value.target.value);
    if (value.target.value === "All") {
      FilteredJobs([...featuredJobs]);
      return;
    }
    let newFeaturedJobs = featuredJobs.filter(
      (job) => job.name.toLowerCase() === value.target.value.toLowerCase()
    );
    FilteredJobs(newFeaturedJobs);
  };

  function getJobs() {
    Loading(true);
    fetch(`${host}/api/${v}/visitor/jobs`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        FeaturedJobs(data.jobs);
        FilteredJobs(data.jobs);
        let nameAr = [...new Set(data.jobs.map((job) => job.name))];
        Names(nameAr);
      })
      .catch((error) => {
        // handleClick({ vertical: "top", horizontal: "right" });
        console.error("Error during fetch:", error);
      })
      .finally(() => {
        // navigate("/register/steps/welcome");
        Loading(false);
      });
  }
  useEffect(() => getJobs(), []);
  return (
    <React.Fragment>
      <Navbar />
      <div className="hero1">
        <img src="./bg.png" style={{ height: "250px", width: "100%" }} />
        <div className="text-overlay">
          <div className="pageTitle">Find Teaching Jobs in India</div>
          <p className="hdtext">
            Search hundreds of teaching jobs abroad, including opportunities
            <br />
            with international schools, government programs, universities <br />
            and language colleges in INDIA.
          </p>
        </div>
      </div>
      <div className="featured">
        <div className="hedFlex">
          <div className="mainHed">Featured Jobs</div>
          <div>Found {filteredJobs.length} Jobs</div>
        </div>
        <SizedBox height="20px" />
        {/* <div style={{ display: "flex" }}>
          <InputLabel
            id="demo-simple-select-label"
            style={{ display: "flex", alignItems: "center" }}
          >
            Job Type
          </InputLabel>
          <SizedBox width="10px" />
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={job}
            style={{ width: "200px" }}
            onChange={handleJobChange}
          >
            <MenuItem key={99999} value={"All"}>
              All
            </MenuItem>
            ;
            {names.map((v, i) => {
              return (
                <MenuItem key={i} value={v}>
                  {v}
                </MenuItem>
              );
            })}
          </Select>
        </div> */}

        {loading ? (
          <LoadingSpinner />
        ) : filteredJobs.length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
            }}
          >
            <p>No Job Found!</p>
          </div>
        ) : (
          filteredJobs.map((value) => {
            return <FeaturedJob value={value} />;
          })
        )}
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default SeeJobs;
