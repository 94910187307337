import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import style from "./style.module.css";

const TutorNavbar = () => {
  const navigate = useNavigate();
  const [tutName, TutName] = useState();

  function getName() {
    try {
      const token = localStorage.getItem("auth");
      const name = jwtDecode(token).name;
      // console.log(jwtDecode(token));
      if (name) {
        TutName(name);
      }
    } catch {}
  }

  useEffect(() => getName(), []);

  async function Logout() {
    localStorage.clear();
    navigate("/");
  }
  return (
    <React.Fragment>
      <div className={style.nav}>
          <img className={style.title} src="/logo.png" />
        <div className={style.nav_links}>
          <Link className={style.link} to="/tutor/dashboard">
            <Button variant="text">Dashboard</Button>
          </Link>
          <Link className={style.link} to="/tutor/dashboard/see_jobs">
            <Button variant="text">Find Jobs</Button>
          </Link>
          <Link className={style.link} to="/tutor/dashboard/applied_jobs">
            <Button variant="text">Applied Jobs</Button>
          </Link>
        </div>
        <div>
          <Link className={style.tutName} to="/tutor/profile">
            {tutName}
          </Link>
          <Button
            variant="contained"
            color="error"
            aria-label="outlined primary button group"
            className={style.rightDiv}
            onClick={() => Logout()}
          >
            Logout
          </Button>
        </div>
      </div>
      <div className={style.bar} />
    </React.Fragment>

  );
};

export default TutorNavbar;
