import React, { useState } from "react";
import Navbar from "../../CommonWidget/Navbar/Navbar";
import { Button, Container, TextField } from "@mui/material";
import SizedBox from "../../Widgets/SizedBox";
import LoadingSpinner from "../../CommonWidget/Loading/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";

const RegisterOption = () => {
  const navigate = useNavigate();

  function handleOptionSelect(option) {
    if (option === 1) {
      navigate("/register_tutor");
    }
    if (option === 2) {
      navigate("/salesteam");
    }
  }

  return (
    <React.Fragment>
      <Navbar />
      <div className={styles.body}>
        <Container
          className={`${styles.reg}  XCon`}
          component="main"
          maxWidth="xs"
        >
          <h3>Please Choose One Option :</h3>
          <div>
            <Button
              variant="outlined"
              className={styles.options}
              onClick={() => handleOptionSelect(1)}
            >
              I am a Tutor
            </Button>
            <Button
              variant="outlined"
              className={styles.options}
              onClick={() => handleOptionSelect(2)}
            >
              I am an Employer
            </Button>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RegisterOption;
