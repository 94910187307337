import React from "react";
import styles from "./styles.module.css";

const Achivements = ({ achivement }) => {
  return (
    <div className={styles.card}>
      <div className={styles.apart}>
        <div className={styles.title}>{achivement.name}</div>
        <div className={styles.date}>{achivement.date}</div>
      </div>
      <div className={styles.description}>{achivement.description}</div>
    </div>
  );
};

export default Achivements;
