import React from "react";
import "./FeaturedJob.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCertificate,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
// import { useNavigate } from "react-router-dom";

const FeaturedJob = ({ imageUrl, title, member1, member2, member3 }) => {
  // const navigate = useNavigate();
  return (
    <div className="horizontal-card">
      <img src={imageUrl} alt="" className="card-image" />
      <div className="card-content">
        <h3 className="card-title">{title}</h3>
        <div className="row-members">
          <FontAwesomeIcon icon={faBuilding} /> <div>{member1}</div>
          <FontAwesomeIcon icon={faMapMarkerAlt} /> <div>{member2}</div>
          <FontAwesomeIcon icon={faCertificate} />
          <div>{member3}</div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedJob;
